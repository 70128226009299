import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { ReportError, DefaultErrorHandler } from "tdsAppRoot/library/ErrorReporter.js";
import SearchModule from "tdsAppRoot/store/SearchState.js";
import DocumentModule from "tdsAppRoot/store/DocumentState.js";
import TocModule from "tdsAppRoot/store/TocState.js";
import BookshelfModule from "tdsAppRoot/store/BookshelfState.js";
import ToolModule from "tdsAppRoot/store/ToolState.js";
import { DocAddressToFxId, GetDocKeywords } from "tdsAppRoot/API/DocumentAPI.js";
import { GetCurrentMSRProfile } from "tdsAppRoot/API/MyStatrefAPI.js";
import { FavTypeCheck } from "tdsAppRoot/library/Favorites.js";
import { DeleteAllServerFavorites, DeleteListOfFavorites, DeleteFavorite, ConvertFavorite, ClearFavResolveState } from "tdsAppRoot/API/FavoritesAPI.js";
import { Encrypt, Decrypt, isLocalStorageEnabled, IsSessionStorageEnabled, ImportingForTheStringExtensions, RemoveHighlightMarkup } from "tdsAppRoot/library/TDSUtil.js";

Vue.use(Vuex);


let CreateStore = function ()
{
	let storeArgs = {
		strict: process.env.NODE_ENV !== 'production',
		modules: {
			search: SearchModule,
			doc: DocumentModule,
			toc: TocModule,
			bookshelf: BookshelfModule,
			tool: ToolModule
		},
		plugins: IsSessionStorageEnabled() ? [createPersistedState({
			storage: window.sessionStorage,
			setState: function (pkey, state, storage)
			{
				for (let key in state)
				{
					if (key === "doc" || key === "dictionaryDetails" || key === "apiRecents" || key === "minorErrorQueue") // dictionaryDetails is always initialized fresh from the server, and is null whenever there is no session ID.
						continue;
					if (key === "toc")
					{
						let subset = new Object();
						Object.assign(subset, state[key]);
						subset.$tocNodeCache = new Object();
						storage.setItem(pkey + "-" + key, JSON.stringify(subset));
					}
					else if (key === "search")
					{
						let subset = new Object();
						Object.assign(subset, state[key]);
						subset.searchResultHistory = new Object();
						let searchJson = JSON.stringify(subset);
						let changed = searchJson !== storage.getItem(pkey + "-" + key);
						if (changed)
						{
							storage.setItem(pkey + "-" + key, searchJson);
							SaveEncryptedSearchHistory(state.sid, searchJson);
						}
					}
					else
						storage.setItem(pkey + "-" + key, JSON.stringify(state[key]));
				}
			},
			getState: function (pkey, storage, value)
			{
				try
				{
					// Here we are supposed to deserialized stored data and build the entire state object.
					let newObj = new Object();
					for (let i = 0; i < storage.length; i++)
					{
						let key = storage.key(i);

						if (key.substr(0, pkey.length + 1) === (pkey + "-") && (value = storage.getItem(key)) && typeof value !== 'undefined')
						{
							key = key.substr(pkey.length + 1);
							newObj[key] = JSON.parse(value);
						}
					}

					// Load encrypted search history from localStorage.  If it is valid for our session then it will override the search history we get from sessionStorage.  It is by this mechanism that search state can be transfered between browser tabs.
					let sid = newObj.sid;
					if (!sid && window && window.appContext && window.appContext.newSid)
						sid = window.appContext.newSid;
					let searchObj = LoadEncryptedSearchHistory(sid);
					if (searchObj)
						newObj["search"] = searchObj;

					return newObj;
				} catch (err) { }

				return undefined;
			}

		})] : [],
		state: {
			// Environment
			app: "", // "" for home app, "login" for login app.
			appPath: "/",
			contentRootPath: "dist/",
			host: "",
			protocol: "http://",

			// Data
			sid: "",
			grpalias: null, // Currently only used by the EULA, to set group specific cookies to prevent the EULA from reappearing.
			sidValidTimestamp: 0,
			profile: null,
			recentHistory: {
				currentPage: "recentsearches",
				currentPageName: "Recent Searches"
			},
			profilePage: {
				currentPage: "profiledetails",
				currentPageName: "Account Settings"
			},
			alertsPage: {
				currentPage: "alertArticles",
				currentPageName: "Articles"
			},
			favoritesList: {
				currentFav: 0,
				scrollPosition: 0
			},
			topbar: {
				expanded: true // If true, the top bar and document toolbar should be allowed to be fixed-position
			},
			hideAllFavorites: false,
			docAddressToFxId: new Object(), // Cached map of docAddress to fxid.  Initially empty, slowly populated as necessary.  There is an action to get a mapping from the server.
			docKeywords: new Object(), // The keyword cache.  A map of docaddress to KeywordResponse objects representing the top concepts found in the document.
			$scrollPosition: new Object(), // the $ makes this non reactive.
			currentMenu: null, // Current open menu.  Other menus reactively detect that it isn't them, and close themselves.
			$profileRegInputs: null,
			$loginUserName: null,
			serviceReady: true, // State of ShadowSvr
			dictionaryDetails: appContext ? JSON.parse(JSON.stringify(appContext.dictionary)) : null, // ex: {"docAddress":"agSgx7Uq0raRoiZktrvQit!!","id":8,"name":"Stedman's Medical Dictionary"}
			apiRecents: [], // Metadata about recent API calls, ordered from newest to oldest.
			slideBarContentsVisible: false,
			// search stuff that shouldn't get persisted
			suggestionHighlightIndex: 0, // Currently highlighted search suggestion.
			mainSuggestionsVisible: false,
			adiSuggestionsVisible: false,
			minorErrorQueue: [],
			fullScreenLoadingMsgVisible: false, // only gets reset to the false state if the instance of FullScreenLoadingMessage uses v-if instead of v-show
			patientInformationHandoutFormValues: {},
			docToolbarMenuOpen: false, // When a drop down menu on the document toolbar is currently open, this is set to true.  Returns to false when the menu is closed.
			highlightWarningReactionCounter: 0 // This is a forced-reactivity variable used by Document.vue and Favorite.vue for making highlight resolution warning icons disappear.
		},

		mutations: // These can't do async code (use actions for that)
		{
			IncrementHighlightWarningReactionCounter(state)
			{
				state.highlightWarningReactionCounter++;
			},
			SetFullScreenLoadingMsgVisible(state, value)
			{
				state.fullScreenLoadingMsgVisible = value;
			},
			SetServerState(state, value)
			{
				state.serviceReady = value;
			},
			SetApp: (state, appName) =>
			{
				state.app = appName;
			},
			InitAppPath: (state, payload) =>
			{
				state.appPath = payload.appPath;
				state.contentRootPath = payload.contentRootPath;
				state.host = window.location.hostname + (window.location.port ? ":" + window.location.port : "");//payload.host;
				state.protocol = payload.protocol;
			},
			NavRecentHistory: (state, args) =>
			{
				state.recentHistory.currentPage = args.pageId;
				state.recentHistory.currentPageName = args.pageName;
			},
			NavProfile: (state, args) =>
			{
				state.profilePage.currentPage = args.pageId;
				state.profilePage.currentPageName = args.pageName;
			},
			NavAlerts: (state, args) =>
			{
				state.alertsPage.currentPage = args.pageId;
				state.alertsPage.currentPageName = args.pageName;
			},
			SetDocToolbarMenuOpenState: (state, open) =>
			{
				state.docToolbarMenuOpen = open;
			},
			SetSessionID: (state, payload) =>
			{
				if (payload)
					Vue.cookie.set("STATRefSID", payload, 1);
				else
					Vue.cookie.delete("STATRefSID");
				state.sid = payload;
			},
			SetToolbarExpanded: (state, payload) =>
			{
				state.topbar.expanded = payload;
			},
			SetGrpAlias: (state, payload) =>
			{
				state.grpalias = payload;
			},
			ClearFavoritesCache: (state) =>
			{
				state.profile.favorites = null;
				state.profile.favmap = null;
			},
			ProfileLoggedIn_Internal: (state, profileData) => // Should be called only by the ProfileLoggedIn action in this store
			{
				if (profileData && profileData.ProfileEmail)
					state.profile = profileData;
				else if (typeof(profileData) !== 'undefined')
					throw Error("ProfileLoggedIn mutation received bad payload: " + JSON.stringify(profileData));
				else
					throw Error("ProfileLoggedIn mutation received bad payload: profileData undefined.");
			},
			ProfileLoggedOut_Internal: (state) => // Should be called only by the ProfileLoggedOut action in this store
			{
				state.profile = null;
				store.commit("InvalidateDocumentCache"); // A document could have highlights in it that are no longer valid without the profile.
			},
			SetScrollPosition: (state, { key, value }) =>
			{
				state.$scrollPosition[key] = value;
			},
			SetFxidMapping: (state, { docAddress, fxid }) =>
			{
				state.docAddressToFxId[docAddress] = fxid;
			},
			SetCachedKeywords: (state, { key, content }) =>
			{
				Vue.set(state.docKeywords, key, content);
			},
			SetFavorites: (state, { favorites, favmap/*, bydoc*/ }) =>
			{
				if (state.profile)
				{
					Vue.set(state.profile, "favorites", favorites);
					Vue.set(state.profile, "favmap", favmap);
					let hlCount = 0;
					for (let i = 0; i < favorites.length; i++)
					{
						if (favorites[i].highlightLength > 0)
							hlCount++;
					}
					state.profile.highlightCount = hlCount;
					state.profile.favCount = favorites.length - hlCount;
					//Vue.set(state.profile, "favbydoc", bydoc);
				}
			},
			SetFavListScrollPosition: (state, scrollPos) =>
			{
				state.favoritesList.scrollPosition = scrollPos;
			},
			SetHideAllFavs: (state, hide) =>
			{
				state.hideAllFavorites = hide;
			},
			//AddFavorite: (state, favData) =>
			//{
			//	if (!state.profile)
			//		throw new Error("Can't add favorite - not logged in to a profile.");

			//	if (!state.profile.favorites)
			//		throw new Error("Favorites not loaded.  Can't add favorite.");

			//	// Favorites are in fxid, docid, paragraph order.
			//	// Find the right place for this new favorite.
			//	for (let i = 0; i < state.profile.favorites; i++)
			//	{

			//	}
			//},

			// fav: An existing favorite to update.
			// favdata: New favorite to add, or from which to copy values.
			AddOrUpdateFavorite: (state, { fav, favData }) =>
			{
				if (favData && favData.type && favData.type.indexOf("anon") != -1)
					return; // We don't want these in the cache, they're not tracked here.
				//store: this.$store, type: fav.type, docAddress: fav.docAddress, text: fav.text, context: GetParagraphText(para), docDate: this.cachedDoc.docInfo.dateMod,
				//paraid: para.id, id: fav.id, path: GetPath(para)
				if (fav)
				{
					for (let k in favData)
					{
						if (favData.hasOwnProperty(k))
							Vue.set(fav, k, favData[k]);
					}
				}
				else
				{
					if (!state.profile)
						throw new Error("Can't add or update favorite - profile not logged in.");
					if (!state.profile.favorites)
						Vue.set(state.profile, "favorites", new Array());
					if (!state.profile.favmap) // Enable fast favorite retrieval by ID.
						Vue.set(state.profile, "favmap", new Object());

					let done = false;
					for (let existingFav in state.profile.favorites)
					{
						if (existingFav.id === favData.id)
						{
							for (let k in favData)
							{
								if (favData.hasOwnProperty(k))
									Vue.set(existingFav, k, favData[k]);
							}
							done = true;
							break;
						}
					}
					if (!done)
					{
						state.profile.favorites.push(favData);
						state.profile.favmap[favData.id] = favData;

						if (favData.highlightLength > 0)
							state.profile.highlightCount++;
						else
							state.profile.favCount++;

						//if (!bydoc[favData.docAddress])
						//	bydoc[favData.docAddress] = new Object();
						//if (!bydoc[favData.docAddress][favData.offset])
						//	bydoc[favData.docAddress][favData.offset] = [];
						//bydoc[favData.docAddress][favData.offset].push(favData);
					}

				}
				//Vue.set(fav, "type", favData.type);
				//Vue.set(fav, "docAddress", favData.docAddress);
				//Vue.set(fav, "text", favData.text);
				//Vue.set(fav, "context", favData.context);
				//Vue.set(fav, "docDate", favData.docDate);
				//Vue.set(fav, "paraid", favData.paraid);
				//Vue.set(fav, "id", favData.id);
				//Vue.set(fav, "path", favData.path);
			},
			SetFavHideState: (state, { fav, hidden }) =>
			{
				if (state.profile && state.profile.favorites && fav)
					Vue.set(fav, "hidden", hidden);
			},
			ClearFavResolveState: (state, fav) =>
			{
				if (state.profile && state.profile.favorites && fav)
					Vue.set(fav, "resolveStatus", 0);
			},
			SetFavResolveState: (state, { fav, resolveState }) =>
			{
				if (state.profile && state.profile.favorites && fav)
					Vue.set(fav, "resolveStatus", resolveState);
			},
			//SetFavPath: (state, { fav, path }) =>
			//{
			//	if (state.profile && state.profile.favorites && fav)
			//	{
			//		Vue.set(fav, "path", path);
			//	}
			//},
			DeleteFavorite: (state, fav) =>
			{
				if (state.profile && state.profile.favorites && fav)
				{
					for (let i = 0; i < state.profile.favorites.length; i++)
						if (state.profile.favorites[i] === fav)
						{
							if (fav.highlightLength > 0)
								state.profile.highlightCount--;
							else
								state.profile.favCount--;
							state.profile.favorites.splice(i, 1);

							break;
						}
					state.profile.favmap[fav.id] = null;
					store.commit("InvalidateDocumentCache");
				}
			},
			DeleteListOfFavs: (state, favidlist) =>
			{
				if (state.profile && state.profile.favorites && favidlist && favidlist.length > 0)
				{
					for (let i = 0; i < favidlist.length; i++)
					{
						for (let j = 0; j < state.profile.favorites.length; j++)
						{
							if (state.profile.favorites[j].id === favidlist[i])
							{
								let fav = state.profile.favorites[j];
								if (fav.highlightLength > 0)
									state.profile.highlightCount--;
								else
									state.profile.favCount--;
								state.profile.favorites.splice(j, 1);
								if (FavTypeCheck(fav, 'hl'))
									RemoveHighlightMarkup(fav.id);
								break;
							}
						}

						state.profile.favmap[favidlist[i]] = null;
					}
					store.commit("InvalidateDocumentCache");
				}
			},
			DeleteAllFavorites: (state, objectType) =>
			{
				if (state.profile && state.profile.favorites)
				{ // This must now only delete favorites, not highlights.

					for (let i = 0; i < state.profile.favorites.length; i++)
					{
						let fav = state.profile.favorites[i];
						if (FavTypeCheck(fav, objectType))
						{
							Vue.set(state.profile.favmap, state.profile.favorites[i].id, null);
							state.profile.favorites.splice(i--, 1);
							if (fav.highlightLength > 0)
								state.profile.highlightCount--;
							else
								state.profile.favCount--;
							RemoveHighlightMarkup(fav.id);
						}
					}
					store.commit("InvalidateDocumentCache"); 
					// Don't do the below, it ignores objectType
					//state.profile.favorites = null;
					//state.profile.favmap = null;


				}
			},
			ConvertFavorite: (state, { favid, bAnnotation }) =>
			{
				if (state.profile && state.profile.favorites)
				{
					if (state.profile.favmap[favid])
					{
						let fav = state.profile.favmap[favid];
						if (fav.type.indexOf("fav") !== -1 && bAnnotation)
							fav.type = fav.type.replace(/fav/, "");
						else if (fav.type.indexOf("fav") === -1 && fav.type.indexOf("anon") === -1 && !bAnnotation)
							fav.type = "fav" + fav.type;
					}
				}
			},
			//RemoveHighlightFromFav: (state, { favid }) =>
			//{
			//	if (state.profile && state.profile.favorites)
			//	{
			//		if (state.profile.favmap[favid])
			//		{
			//			let fav = state.profile.favmap[favid];
			//			fav.highlightStartOffset = -1;
			//			fav.highlightLength = -1;
			//			fav.highlightText = null;
			//		}
			//	}
			//},
			SetCurrentFav: (state, idx) =>
			{
				state.favoritesList.currentFav = idx;
			},
			SetCurrentMenu: (state, menuid) =>
			{
				state.currentMenu = menuid;
			},
			SetProfileRegistrationField(state, { fieldName, value })
			{
				if (!state.$profileRegInputs)
					state.$profileRegInputs = {};
				state.$profileRegInputs[fieldName] = value;
			},
			ClearProfileRegistrationFields(state)
			{
				state.$profileRegInputs = null;
			},
			SetLoginUserName(state, value)
			{
				state.$loginUserName = value;
			},
			SetToolActiveState(state, { componentKey, inactive })
			{
				if (state.profile)
				{
					let index = state.profile.hiddenTools.findIndex(key => key === componentKey);
					if (inactive)
					{
						if (index < 0)
							state.profile.hiddenTools.push(componentKey);
					}
					else
					{
						if (index >= 0)
							state.profile.hiddenTools.splice(index, 1);
					}
				}
			},
			EditLocalTitleTool: (state, { fxid, addTitle, nameHtml }) =>
			{
				if (state.profile)
				{
					// We keep track of fxids that we add and also those which we remove so that removed ones won't re-appear if they are part of the group's defaults.
					let indexAdded = state.profile.addedCustomTitleTools.findIndex(tool => tool.fxid === fxid);
					let indexRemoved = state.profile.removedCustomTitleTools.findIndex(f => f === fxid);
					if (addTitle)
					{
						if (indexAdded < 0) // add to "added" list
							state.profile.addedCustomTitleTools.push({ fxid, nameHtml });
						if (indexRemoved >= 0) // remove from "removed" list
							state.profile.removedCustomTitleTools.splice(indexRemoved, 1);
					}
					else
					{
						if (indexRemoved < 0) // add to "removed" list
							state.profile.removedCustomTitleTools.push(fxid);
						if (indexAdded >= 0) // remove from "added" list
							state.profile.addedCustomTitleTools.splice(indexAdded, 1);
					}
				}
			},
			SetDictionaryDetails(state, { id, name, docAddress })
			{
				appContext.dictionary = JSON.parse(JSON.stringify({ id, name, docAddress }));
				state.dictionaryDetails = { id, name, docAddress };
			},
			TrackAPICall(state, apiCall)
			{
				if (state.apiRecents.length > 4)
					state.apiRecents = state.apiRecents.slice(0, 4);
				state.apiRecents.splice(0, 0, apiCall);
			},
			SetSlideBarContentsVisible(state, value)
			{
				state.slideBarContentsVisible = value;
			},
			SetSuggestionHighlightIndex(state, index)
			{
				state.suggestionHighlightIndex = index;
			},
			SetSuggestionsVisible(state, { id, vis })
			{
				if (id == "mainSearchSuggestions")
					state.mainSuggestionsVisible = vis;
				else
					state.adiSuggestionsVisible = vis;
			},
			EnqueueMinorError(state, message)
			{
				state.minorErrorQueue.push(message);
				if (state.minorErrorQueue.length > 250)
					state.minorErrorQueue.splice(0, 1);
			},
			ClearMinorErrorQueue(state)
			{
				state.minorErrorQueue = [];
			},
			SetPatientInformationHandoutFormValue(state, { key, enable, value })
			{
				if (!state.patientInformationHandoutFormValues)
					state.patientInformationHandoutFormValues = {};
				state.patientInformationHandoutFormValues[key] = { enable, value };
			},
			ClearPatientInformationHandoutForm(state)
			{
				state.patientInformationHandoutFormValues = {};
			}
		},

		actions: // Async code can go here.
		{
			
			GetFxId({ commit, dispatch, state, rootState, rootGetters }, { docAddress })
			{
				if (state.docAddressToFxId[docAddress])
				{
					console.log("cached fx id");
					return Promise.resolve(state.docAddressToFxId[docAddress]);
				}
				console.log("fx id not cached.  Getting fx id from server.");

				if (appContext.badDocAddress && appContext.badDocAddress == docAddress)
				{
					return Promise.reject("There appears to be a problem with the link you used to access this document.  We are sorry for the inconvenience.  Please contact technical support if the problem persists.");
				}
				else
				{
					return DocAddressToFxId(docAddress, { state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch })
						.then(data =>
						{
							commit("SetFxidMapping", { docAddress: docAddress, fxid: data.fxid });
							return Promise.resolve(data.fxid);
						}).catch(err =>
						{
							return Promise.reject(err);
						});
				}
			},
			RefreshProfile({ commit, dispatch, state, rootState, rootGetters })
			{
				console.log("Profile change detected.  Refreshing profile.");
				GetCurrentMSRProfile({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch })
					.then(data =>
					{
						console.log("Profile: " + (state.profile ? state.profile.ProfileEmail : "none") + "   changed to: " + data.profileData);
						if (typeof data.profileData !== 'undefined' && data.profileData)
							dispatch("ProfileLoggedIn", { profileData: data.profileData, disableAutomaticDocReload: false });
					})
					.catch(err =>
					{
						return Promise.reject(err);
					});
			},
			ProfileLoggedIn: ({ commit, dispatch, state, rootState, rootGetters }, { profileData, disableAutomaticDocReload }) =>
			{
				commit("ClearDocumentCache");
				commit("ProfileLoggedIn_Internal", profileData);
				if (state.profile)
					commit("SetLocalToolOrder", state.profile.toolOrder);
				dispatch("ResetBookshelfCategories");
				// Because we cleared the document cache, if a document is displayed we need to tell it to reload from the server.
				if (!disableAutomaticDocReload)
				{
				let doc = document.getElementById("docRootDomNode"); 
				if (doc)
					doc.component.LoadDoc();
					}
			},
			ProfileLoggedOut: ({ commit, dispatch, state, rootState, rootGetters }) =>
			{
				commit("ProfileLoggedOut_Internal");
				dispatch("ResetBookshelfCategories");
			},
			GetDocKeywords({ commit, dispatch, state, rootState, rootGetters }, { docAddress })
			{
				if (state.docKeywords[docAddress])
					return Promise.resolve(state.docKeywords[docAddress]);
				return GetDocKeywords(docAddress, { state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch })
					.then(data =>
					{
						commit("SetCachedKeywords", { key: docAddress, content: data });
						return Promise.resolve(data);
					}).catch(err =>
					{
						return Promise.reject(err);
					});
			},
			DeleteFavorite({ commit, dispatch, state, rootState, rootGetters }, favid)
			{
				return DeleteFavorite({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, favid).then(result =>
				{
					commit("DeleteFavorite", rootGetters.getFavorite(favid));
				}).catch(err =>
				{
					return Promise.reject(err);
				});
			},
			DeleteListOfFavs({ commit, dispatch, state, rootState, rootGetters }, favIdList)
			{
				return DeleteListOfFavorites({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, favIdList).then(result =>
				{
					commit("DeleteListOfFavs", favIdList);
				}).catch(err =>
				{
					return Promise.reject(err);
				});
			},
			DeleteAllFavorites({ commit, dispatch, state, rootState, rootGetters }, { objectType }) // objectType can be "anno", "fav", "hl", or just leave it null and it will default to "fav".
			{
				return DeleteAllServerFavorites({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, objectType).then(result =>
				{
					commit("DeleteAllFavorites", objectType);
				}).catch(err =>
				{
					return Promise.reject(err);
				});
			},
			ClearResolveState({ commit, dispatch, state, rootState, rootGetters }, fav)
			{
				let favid;
				if (fav && fav.id)
					favid = fav.id;
				else
				{
					favid = fav;


					if (state.profile && state.profile.favorites)
						fav = state.profile.favmap[favid];
				}
				return ClearFavResolveState({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, favid).then(result =>
				{

					commit("ClearFavResolveState", fav);
				}).catch(err =>
				{
					return Promise.reject(err);
				});
			},
			ConvertFavorite({ commit, dispatch, state, rootState, rootGetters }, { favid, bAnnotation }) // Converts document favorites to and from document annotations, which affects their display in documents.  Annotations have a visible dialog bubble that appears in documents when ShowAnnotations is selected, or when the user navigates to the annotation.
			{
				return ConvertFavorite({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, favid, bAnnotation)
					.then(result =>
					{
						commit("ConvertFavorite", { favid, bAnnotation });
					})
					.catch(err =>
					{
						return Promise.reject(err);
					});
			},
			//RemoveHighlightFromFav({ commit, dispatch, state, rootState, rootGetters }, { favid }) // Removes the highlight info from a favorite.
			//{
			//	return RemoveHighlightFromFav({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, favid)
			//		.then(result =>
			//		{
			//			commit("RemoveHighlightFromFav", { favid });
			//		}).catch(err =>
			//		{
			//			return Promise.reject(err);
			//		});
			//}
		},
		getters: {
			/// Full url root, ending with /
			urlRoot: function (state)
			{
				return state.protocol + state.host + state.appPath + ((state.appPath.length > 0 && state.appPath[state.appPath.length - 1] === '/') ? '' : '/');
			},
			appPath: function (state)
			{
				return state.appPath;
			},
			sid: function (state)
			{
				return state.sid;
			},
			profile: function (state)
			{
				return state.profile;
			},
			recentHistoryPageName: function (state)
			{
				return state.recentHistory.currentPageName;
			},
			profilePageName: function (state)
			{
				return state.profilePage.currentPageName;
			},
			alertsPageName: function (state)
			{
				return state.alertsPage.currentPageName;
			},
			//sessionValidCache: function (state) {
			//	if (!state.sidValidTimestamp)
			//		return false;
			//	let now = new Date();
			//	const sidValidFor = 60;
			//	if (now.getSeconds() - state.sidValidTimestamp.getSeconds() < sidValidFor) {
			//		return true;
			//	}
			//	return false;
			//}
			GetProfileRegistrationField(state)
			{
				return fieldName =>
				{
					if (state.$profileRegInputs)
						return state.$profileRegInputs[fieldName];
					return null;
				};
			},
			getTitleFavorites(state)
			{
				return fxid =>
				{
					if (state.profile && state.profile.favorites)
						return state.profile.favorites.filter(item => item.fxid === fxid);
					else
						return [];
				};
			},
			getFavorite(state)
			{
				return favid =>
				{
					if (state.profile && state.profile.favorites)
						return state.profile.favmap[favid];
					else
						return null;
				};
			}

		}
	};
	let store = new Vuex.Store(storeArgs);
	// The following enables each module to define its own watchers using custom syntax. See BookshelfState.js.
	for (let moduleName in storeArgs.modules)
	{
		let module = storeArgs.modules[moduleName];
		if (module.watchers)
			module.watchers.forEach(params =>
			{
				store.watch(params[0], (newValue, oldValue) =>
				{
					params[1](newValue, oldValue, store);
				});
			});
	};
	return store;
};

/**
 * Encrypts the search history JSON string using the session id as the encryption key, and stores the encrypted value in localStorage.
 * @param {String} sid Session ID of the current session.
 * @param {String} searchJson Search history as a JSON string.
 */
function SaveEncryptedSearchHistory(sid, searchJson)
{
	try
	{
		// Encrypt search history using session id, put in localStorage so it can be reloaded on other browser tabs.
		if (isLocalStorageEnabled() && sid)
		{
			searchJson = searchJson + ":VALID"; // After decryption, we must find this string on the end
			let encrypted = Encrypt(sid, searchJson);
			localStorage.setItem("vuex-enc-search", encrypted);
			console.log("Successfully wrote encrypted search state to localStorage");
		}
	}
	catch (ex)
	{
		console.error(ex);
		if (window && window.appContext)
			ReportError(appContext.urlRoot, ex.message, null, ex, sid);
	}
}
/**
 * Returns search history that was encrypted in localStorage.
 * If for any reason this is not possible (such as the session ID being different), null is returned instead.
 * @param {String} sid Session ID of the current session.
 * @returns {Object} search history or null
 */
function LoadEncryptedSearchHistory(sid)
{
	try
	{
		if (isLocalStorageEnabled() && sid)
		{
			let encrypted = localStorage.getItem("vuex-enc-search");
			if (encrypted)
			{
				try
				{
					let searchJson = Decrypt(sid, encrypted);
					if (searchJson && searchJson.endsWith(":VALID"))
					{
						searchJson = searchJson.substr(0, searchJson.length - ":VALID".length);
						let searchObj = JSON.parse(searchJson);
						console.log("Successfully loaded encrypted search state from localStorage");
						return searchObj;
					}
				}
				catch (err)
				{
					localStorage.removeItem("vuex-enc-search");
					console.log("Removed invalid encrypted search state from localStorage");
				}
			}
		}
	}
	catch (ex)
	{
		console.error(ex);
		if (window && window.appContext)
			ReportError(appContext.urlRoot, ex.message, null, ex, sid);
	}
	return null;
}

export default CreateStore;