var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "category" }, [
    _c("div", { staticClass: "heading" }, [_vm._v(_vm._s(_vm.categoryType))]),
    _vm._v(" "),
    _c("div", { staticClass: "body" }, [
      _vm.category
        ? _c("table", [
            _c(
              "tbody",
              _vm._l(_vm.lineItems, function (item, index) {
                return _c("tr", { key: index, staticClass: "item" }, [
                  _c("td", { staticClass: "itemKey" }, [
                    _vm._v(_vm._s(item.key) + ":"),
                  ]),
                  _vm._v(" "),
                  _c("td", {
                    staticClass: "itemValue",
                    domProps: { innerHTML: _vm._s(item.value) },
                  }),
                ])
              }),
              0
            ),
          ])
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "loading" },
            [
              _c("ScaleLoader", { staticClass: "animation" }),
              _vm._v(" "),
              _c("div", { staticClass: "loadingtxt" }, [_vm._v("Loading")]),
            ],
            1
          )
        : _vm.canceled
        ? _c("div", { staticClass: "canceled" }, [
            _vm._v("\n\t\t\tCanceled\n\t\t"),
          ])
        : _c("div", { staticClass: "failed" }, [
            _vm._v("\n\t\t\tResult set did not load\n\t\t"),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }