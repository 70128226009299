var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.titleInfoRootClasses },
    [
      _vm.success
        ? [
            _c(
              "DynamicLink",
              {
                staticClass: "titleLink",
                attrs: {
                  to: _vm.linkTo,
                  href: _vm.linkHref,
                  div: _vm.coverPicIsDiv,
                  insetOutline: false,
                },
              },
              [
                _c("img", {
                  staticClass: "titleCoverImage",
                  attrs: {
                    alt: "",
                    role: "presentation",
                    src: _vm.titleImgSrc,
                  },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "titleName",
                  domProps: { innerHTML: _vm._s(_vm.name) },
                }),
              ]
            ),
            _vm._v(" "),
            _c("p"),
            _vm._v(" "),
            _vm.favorites
              ? _c(
                  "div",
                  { staticClass: "favoritesContainer" },
                  [
                    _c("div", { staticClass: "favoritesHeading" }, [
                      _vm._v("Favorites"),
                    ]),
                    _vm._v(" "),
                    _c("MiniFavoritesList", {
                      staticClass: "favoritesList",
                      attrs: { favorites: _vm.favorites },
                    }),
                  ],
                  1
                )
              : _c("span", {
                  staticClass: "titleInfoTooltipDescription",
                  domProps: { innerHTML: _vm._s(_vm.description) },
                }),
          ]
        : _vm.error
        ? _c("div", [_vm._v(_vm._s(_vm.error))])
        : _c(
            "div",
            { staticClass: "loading" },
            [
              _c("ScaleLoader", { staticClass: "animation" }),
              _vm._v(" "),
              _c("div", { staticClass: "loadingtxt" }, [
                _vm._v("Loading description…"),
              ]),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }