var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ltpOuter",
      attrs: { role: "dialog", "aria-labelledby": "ltpTitle" },
    },
    [
      _c(
        "div",
        { staticClass: "titleBar" },
        [
          _c("LogoImage", {
            staticStyle: { "padding-left": "8px" },
            attrs: {
              width: 59,
              includeDesc: false,
              logoType: "onPrimaryColor",
            },
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "button",
            {
              ref: "closeButton",
              staticClass: "closeButton",
              attrs: { tabindex: "0", "aria-label": "Close this dialog box" },
              on: { click: _vm.DefaultClose },
            },
            [
              _c("vsvg", {
                attrs: { sprite: "#x", title: "Close", desc: "Close Dialog" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "linkToPage" }, [
        _c("div", { staticClass: "top" }, [
          _c("div", { staticClass: "leftSide" }, [
            _c("input", {
              ref: "tbLTPText",
              staticClass: "linkText",
              attrs: {
                type: "text",
                readonly: "",
                "aria-label": "current location link text",
                "aria-describedby": "ltpTitle",
              },
              domProps: { value: _vm.theLink },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "rightSide" }, [
            _c(
              "button",
              {
                staticClass: "copyBtn primaryColorButton inputButton",
                attrs: { tabindex: "0" },
                on: { click: _vm.OnCopyClick },
              },
              [_vm._v("Copy")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bottom" }, [
          _vm.showPreviewWarning
            ? _c("div", { staticClass: "previewWarning" }, [
                _vm._v(
                  "\n\t\t\t\tPlease note that any links created while using this preview version of " +
                    _vm._s(_vm.appContext.appName) +
                    " may cease functioning when the preview is officially released.\n\t\t\t"
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "authSelectRow" }, [
            _c(
              "label",
              { staticClass: "authSelectLabel", attrs: { for: "authSelect" } },
              [_vm._v("Authentication: ")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.authTypeIdx,
                    expression: "authTypeIdx",
                  },
                ],
                staticClass: "authSelect",
                attrs: {
                  id: "authSelect",
                  role: "menu",
                  tabindex: "0",
                  required: "",
                  "aria-label": "Select Authentication Type For Link",
                  "aria-describedby": "ltpAuthDesc",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.authTypeIdx = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.flattenedOptions, function (opt, idx) {
                return _c(
                  "option",
                  {
                    attrs: {
                      role: "menuitem",
                      "aria-describedby": "ltpAuthDescText",
                    },
                    domProps: { value: idx },
                  },
                  [_vm._v(_vm._s(opt.authOptionText))]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "authDescBlock", attrs: { id: "ltpAuthDesc" } },
            [
              _c(
                "div",
                {
                  staticClass: "authDescText",
                  attrs: { id: "ltpAuthDescText" },
                },
                [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.authDesc) + "\n\t\t\t\t")]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ltpTitle", attrs: { id: "ltpTitle" } }, [
      _vm._v("Link To Current Location"),
      _c("span", { staticClass: "visuallyhidden" }, [
        _vm._v(". Press escape to close this dialog."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }