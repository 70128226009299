var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        modalDialogRoot: true,
        positionAbsolute: _vm.usePositionAbsolute,
      },
      style: _vm.rootStyle,
      attrs: { "aria-hidden": _vm.modalDialogIndex < _vm.modalDialogCount - 1 },
    },
    [
      _c("div", {
        staticClass: "modalDialogOverlay",
        on: { mousedown: _vm.overlayMouseDown, mouseup: _vm.overlayClick },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modalDialogPositioner",
          style: _vm.dynPosStyle,
          on: { mousedown: _vm.overlayMouseDown, mouseup: _vm.overlayClick },
        },
        [
          _vm.useFocusCatcher
            ? _c("div", {
                staticClass: "FocusCatcher",
                attrs: { tabindex: "0" },
                on: {
                  focus: function ($event) {
                    return _vm.FocusCatcher(false)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modalDialogContent",
              style: _vm.dynContentStyle,
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  return _vm.contentMouseDown.apply(null, arguments)
                },
                mouseup: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.noop.apply(null, arguments)
                },
                click: _vm.contentClick,
              },
            },
            [
              _c(
                _vm.contentComponent,
                _vm._b(
                  {
                    ref: "contentComponent",
                    tag: "component",
                    attrs: { "aria-modal": "true" },
                    on: { close: _vm.closeRequested },
                  },
                  "component",
                  _vm.contentProps,
                  false
                )
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.useFocusCatcher
            ? _c("div", {
                staticClass: "FocusCatcher",
                attrs: { tabindex: "0" },
                on: {
                  focus: function ($event) {
                    return _vm.FocusCatcher(true)
                  },
                },
              })
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }