<template>
	<div class="favoriteItems">
		<div v-for="(fav, idx) in favorites" :id="'MiniFavoriteItem' + idx" @keydown.up.prevent="PrevItem" @keydown.down.prevent="NextItem" 
			 :key="fav.favid" :index="idx" class="favoriteItem ieInsetOutlineFV" role="link" tabindex="0" @click="onItemOpen(fav)" 
			 @keypress.enter.prevent="onItemOpen(fav)" v-if="TypeCheck(fav)">
			<vsvg v-if="fav.type.indexOf('srch') !== -1" sprite="#magnifying_glass" title="" desc="" role="presentation" class="searchButtonImg" />
			<span v-html="getText(fav)"></span>
		</div>
	</div>
</template>

<script>
	import svg1 from "tdsAppRoot/images/sprite/magnifying_glass.svg";
	import { GoToFavorite, FavTypeCheck } from "tdsAppRoot/library/Favorites.js";

	export default {
		props:
		{
			favorites: {
				type: Array,
				required: true
			}
		},
		methods:
		{
			TypeCheck: function (fav)
			{
				return FavTypeCheck(fav, "normal");
			},
			onItemOpen(fav)
			{
				GoToFavorite.call(this, fav, false);
			},
			PrevItem(ev)
			{
				this.MoveFocus(ev, -1);
			},
			NextItem(ev)
			{
				this.MoveFocus(ev, 1);
			},
			MoveFocus: function (ev, direction)
			{
				let myidx = parseInt(ev.target.getAttribute("index"));
				myidx = myidx + direction;
				if (myidx < 0)
					myidx = 0;
				if (myidx >= this.favorites.length)
					myidx = this.favorites.length - 1;
				let trgt = document.getElementById("MiniFavoriteItem" + myidx);
				if (trgt)
					trgt.focus();
			},
			getText(fav)
			{
				if (fav.docTitle)
					return fav.docTitle;
				else if (fav.type.indexOf('srch') !== -1)
				{
					let idxSearch = fav.productName.indexOf("Search: ");
					if (idxSearch > -1)
						return fav.productName.substr(idxSearch + "Search: ".length);
					else
						return fav.productName;
				}
				else
					return "(Title" + (fav.discontinued ? "" : " Temporarily") + " Unavailable)";
			}
		}
	}
</script>

<style scoped>
	.favoriteItems
	{
		overflow: auto;
	}

	.favoriteItem
	{
		color: var(--primary-color-text);
		padding: 5px;
		cursor: pointer;
		height: 27px;
		box-sizing: border-box;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

		.favoriteItem:focus
		{
			outline: 2px solid black;
			outline-offset: -3px;
			border-radius: 3px;
		}
		.favoriteItem:hover
		{
			color: var(--primary-color-text-light1);
			background-color: rgba(255,255,255,0.5);
		}

		.favoriteItem:nth-child(even)
		{
			background-color: rgba(0, 25, 74, 0.07);
		}

			.favoriteItem:nth-child(even):hover
			{
				background-color: rgba(0, 25, 74, 0.04);
			}

	.searchButtonImg
	{
		width: 13px;
		height: 13px;
		stroke: var(--search-button-color);
		margin-left: 4px;
		margin-right: 5px;
		position: relative;
		top: 2px;
	}
</style>