var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        { staticClass: "loading" },
        [
          _c("ScaleLoader", { staticClass: "animation" }),
          _vm._v(" "),
          _c("div", { staticClass: "loadingtxt" }, [_vm._v("Loading…")]),
        ],
        1
      )
    : _c("div", [
        _vm.category
          ? _c("div", { staticClass: "summary" }, [
              _c("b", [_vm._v(_vm._s(_vm.totalMatches))]),
              _vm._v(" Hits in "),
              _c("b", [_vm._v(_vm._s(_vm.totalResults))]),
              _vm._v(" Matching Results found in\n\t\t"),
              _c(
                "a",
                {
                  class: {
                    alwaysUnvisited: true,
                    bold: !_vm.showZeroHitTitles,
                  },
                  attrs: { role: "button", tabindex: "0" },
                  on: {
                    click: function ($event) {
                      _vm.showZeroHitTitles = false
                    },
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "enter",
                          13,
                          $event.key,
                          "Enter"
                        ) &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ])
                      ) {
                        return null
                      }
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.showZeroHitTitles = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.titlesWithResults))]
              ),
              _vm._v("\n\t\ttitles of\n\t\t"),
              _c(
                "a",
                {
                  class: { alwaysUnvisited: true, bold: _vm.showZeroHitTitles },
                  attrs: { role: "button", tabindex: "0" },
                  on: {
                    click: function ($event) {
                      _vm.showZeroHitTitles = true
                    },
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "enter",
                          13,
                          $event.key,
                          "Enter"
                        ) &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ])
                      ) {
                        return null
                      }
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.showZeroHitTitles = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.bookHits.length))]
              ),
              _vm._v("\n\t\tTitles Searched, requiring "),
              _c("b", [_vm._v(_vm._s(_vm.searchTimeSeconds))]),
              _vm._v(" seconds\n\t"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.category
          ? _c("table", { staticClass: "resultCountsTbl" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", { staticClass: "results" }, [_vm._v("Results")]),
                  _vm._v(" "),
                  _c("th", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.subProduct) + " Title"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.bookHits, function (item) {
                  return _c(
                    "tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showZeroHitTitles || item.results > 0,
                          expression: "showZeroHitTitles || item.results > 0",
                        },
                      ],
                      key: item.id,
                      staticClass: "item",
                    },
                    [
                      _c("td", { staticClass: "results" }, [
                        _vm._v(_vm._s(item.results)),
                      ]),
                      _vm._v(" "),
                      _c("td", {
                        staticClass: "title",
                        domProps: { innerHTML: _vm._s(item.name) },
                      }),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }