var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      class: {
        toastContainer: true,
        minorErrorsVisible: _vm.minorErrorCount > 0,
      },
      attrs: { name: "toast-trans", tag: "div" },
    },
    _vm._l(_vm.toasts, function (t) {
      return _c("Toast", {
        key: t.key,
        staticClass: "toast-trans-item",
        attrs: { args: t.args },
        on: {
          close: function (toastResult) {
            _vm.closeRequested(t.key, toastResult)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }