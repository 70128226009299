var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mfaRememberedDevices" }, [
    _c("div", { staticClass: "titleBar" }, [_vm._v("Remembered Devices")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "body" },
      [
        _c("div", { staticClass: "rememberedDescription" }, [
          _vm._v(
            "Remembered devices have already been authenticated with MFA and are not required to complete additional tasks when logging in."
          )
        ]),
        _vm._v(" "),
        _vm.rememberedDevices.length === 0
          ? _c("div", [
              _vm._v(
                "\n\t\t\tYour user account does not have any remembered devices.\n\t\t"
              )
            ])
          : _c(
              "div",
              {
                class: {
                  rememberedDeviceList: true,
                  isDeleting: _vm.isDeleting
                }
              },
              [
                _c("div", { staticClass: "rememberedDeviceListHeader" }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.rememberedDevices.length) +
                      " remembered device" +
                      _vm._s(_vm.rememberedDevices.length === 1 ? "" : "s") +
                      "\n\t\t\t"
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.rememberedDevices, function(device) {
                  return _c(
                    "div",
                    { staticClass: "rememberedDevice" },
                    [
                      _c("SvgButton", {
                        staticClass: "rememberedDeviceDelete",
                        attrs: {
                          sprite: "#delete_forever",
                          title: "Delete device",
                          desc: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.DeleteDevice(device)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "rememberedDeviceDescription" },
                        [_vm._v(_vm._s(device.Description))]
                      ),
                      _vm._v(" "),
                      device.IsCurrentDevice
                        ? _c(
                            "div",
                            { staticClass: "rememberedDeviceIsThisDevice" },
                            [_vm._v("You are using this device")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "rememberedDeviceTime" }, [
                        _c("b", [_vm._v("First Login:")]),
                        _vm._v(" " + _vm._s(_vm.DateStr(device.SetupTimeUTC)))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "rememberedDeviceLocation",
                          attrs: { title: "Location at first login" }
                        },
                        [_vm._v(_vm._s(device.Location))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "rememberedDeviceIP",
                          attrs: { title: "IP Address at first login" }
                        },
                        [_vm._v(_vm._s(device.IPAddress))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "rememberedDeviceTime" }, [
                        _c("b", [_vm._v("Last Login:")]),
                        _vm._v(
                          " " + _vm._s(_vm.DateStr(device.LastUsedTimeUTC))
                        )
                      ])
                    ],
                    1
                  )
                })
              ],
              2
            ),
        _vm._v(" "),
        _vm.deviceIsRemembered
          ? void 0
          : !_vm.allowedToRemember
          ? _c("div", { staticClass: "rememberNoteNegative" }, [
              _vm._v("This user is not allowed to add new remembered devices.")
            ])
          : !_vm.ableToRemember
          ? _c("div", { staticClass: "rememberNoteNegative" }, [
              _vm._v("This app/device is not able to be remembered.")
            ])
          : _vm.rememberedDevices.length > 0
          ? _c("div", { staticClass: "rememberNoteNeutral" }, [
              _vm._v("This device is not remembered.")
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }