var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "errorPage",
      attrs: {
        role: "dialog",
        "aria-modal": "true",
        "aria-labelledby": "errMsgLbl",
        "aria-describedby": "errMsgLbl",
      },
    },
    [
      _c("div", { staticClass: "errorPageTitle" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.pageTitle) + "\n\t"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "errorPagePanel" }, [
        _c("div", { staticClass: "errorMessage", attrs: { id: "errMsgLbl" } }, [
          _vm._v(_vm._s(_vm.errMsg)),
        ]),
        _vm._v(" "),
        _vm.link
          ? _c("div", { staticClass: "link" }, [
              _c("a", { attrs: { href: _vm.link } }, [
                _vm._v(_vm._s(_vm.linkTxt)),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }