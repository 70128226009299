var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialogContainer" },
    [
      _c(
        "transition-group",
        { attrs: { name: _vm.transitionName } },
        _vm._l(_vm.components, function (c, idx) {
          return _c(
            c.component,
            _vm._b(
              {
                key: c.key,
                ref: "childModalDialogComponent",
                refInFor: true,
                tag: "component",
                attrs: {
                  modalDialogIndex: idx,
                  modalDialogCount: _vm.components.length,
                },
                on: {
                  close: function (dialogResult) {
                    _vm.closeRequested(c.key, dialogResult)
                  },
                },
              },
              "component",
              c.props,
              false
            )
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }