var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.to
    ? _c(
        "router-link",
        {
          class: {
            ieInsetOutlineFV: _vm.insetOutline,
            ieOutlineFV: !_vm.insetOutline,
            basicFocusOutline: true,
          },
          attrs: { to: _vm.to },
        },
        [_vm._t("default")],
        2
      )
    : _vm.href
    ? _c(
        "a",
        {
          class: {
            ieInsetOutlineFV: _vm.insetOutline,
            ieOutlineFV: !_vm.insetOutline,
            basicFocusOutline: true,
          },
          attrs: { href: _vm.href, target: "_blank" },
        },
        [_vm._t("default")],
        2
      )
    : _vm.div
    ? _c(
        "div",
        { staticClass: "ieInsetOutlineFV basicFocusOutline" },
        [_vm._t("default")],
        2
      )
    : _c(
        "span",
        { staticClass: "ieInsetOutlineFV basicFocusOutline" },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }