var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forgotPwBlock" },
    [
      _c("div", { staticClass: "desc" }, [
        _vm._v(
          "Please enter your " +
            _vm._s(_vm.userNameType) +
            ", and we will email you a link to reset your password."
        ),
      ]),
      _vm._v(" "),
      _c("label", { attrs: { for: "forgotPwUserNameInput" } }, [
        _vm._v(_vm._s(_vm.userNamePlaceholder)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "commonLoginInputs" }, [
        _c(
          "div",
          { staticClass: "loginInputDiv", attrs: { title: "User name" } },
          [
            _c("vsvg", {
              staticClass: "inputIcon",
              attrs: {
                sprite: "#person_fill",
                "aria-hidden": "true",
                title: "",
                desc: "",
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userName,
                  expression: "userName",
                },
              ],
              ref: "userNameInput",
              staticClass: "userNameInput",
              attrs: {
                id: "forgotPwUserNameInput",
                type: "text",
                autocorrect: "off",
                autocapitalize: "none",
                autofocus: "",
                autocomplete: "username",
              },
              domProps: { value: _vm.userName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.userName = $event.target.value
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "invisible-recaptcha",
        {
          ref: "captchaComponent",
          staticClass: "commonLoginButton HideRecaptchaBadge",
          attrs: {
            sitekey: "6LfrjUsUAAAAAIhDbhKQAtBYk4uJ2o8bkpiYIWu3",
            validate: _vm.OnValidate,
            callback: _vm.SendLinkClick,
            type: "submit",
            disabled: false,
            "aria-label": "Send Reset Link",
          },
        },
        [_vm._v("\n\t\tSend Reset Link\n\t")]
      ),
      _vm._v(" "),
      _c("RecaptchaBadgeSubstitute"),
      _vm._v(" "),
      _c("FullScreenLoadingMsg", {
        attrs: { show: _vm.loading, msg: "Processing…" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }