var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.logo
    ? _c(
        "span",
        [
          _vm.img && _vm.img.svgSprite
            ? _c("vsvg", {
                class: _vm.dynSvgClass,
                style: _vm.dynImgStyle,
                attrs: {
                  sprite: _vm.img.svgSprite,
                  title: _vm.logo.title,
                  desc: _vm.desc,
                  isPresentation: _vm.rolePresentation ? true : false,
                },
              })
            : _c("img", {
                staticClass: "logoImg",
                style: _vm.dynImgStyle,
                attrs: { src: _vm.logoImgSrc, title: _vm.logo.title },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }