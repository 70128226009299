var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        mySRLoginRoot: true,
        isInPopup: _vm.isInPopup,
        isStandalone: !_vm.isInPopup,
      },
      attrs: { id: "mySRLoginRoot" },
    },
    [
      _vm.isInPopup
        ? _c("div", { staticClass: "titleBar" }, [
            _c("div", { staticClass: "loginTitle" }, [_vm._v("PROFILE LOGIN")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "body" }, [
        _c("div", { ref: "validateForm", staticClass: "controls" }, [
          _c("div", { staticClass: "userNameBox" }, [
            _c("label", { attrs: { for: "profileLoginEmailBox" } }, [
              _vm._v("Your Email"),
            ]),
            _c("br"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              ref: "emailBox",
              staticClass: "tb",
              attrs: {
                id: "profileLoginEmailBox",
                name: _vm.inputName("email"),
                "data-vv-as": "Your email",
                type: "email",
                "aria-describedby": "profileLoginEmailBoxValid",
                tabindex: "0",
                validatespec: "required",
                maxlength: "250",
                autocomplete: "email",
                "aria-invalid": _vm.validator.has(_vm.inputName("email"))
                  ? "true"
                  : null,
              },
              domProps: { value: _vm.email },
              on: {
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k(
                      $event.keyCode,
                      "escape",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.CloseDialog.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.validator.has(_vm.inputName("email")),
                    expression: "validator.has(inputName('email'))",
                  },
                ],
                staticClass: "validationWarning",
                attrs: { id: "profileLoginEmailBoxValid" },
              },
              [_vm._v(_vm._s(_vm.validator.first(_vm.inputName("email")).msg))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "passwordBox" }, [
            _c("label", { attrs: { for: "profilePwEmailBox" } }, [
              _vm._v("Password"),
            ]),
            _c("br"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pw,
                  expression: "pw",
                },
              ],
              staticClass: "tb",
              attrs: {
                id: "profilePwEmailBox",
                name: _vm.inputName("pw"),
                "data-vv-as": "Password",
                type: "password",
                tabindex: "0",
                validatespec: "required",
                maxlength: "120",
                autocomplete: "current-password",
                "aria-describedby": "profilePwEmailBoxValid",
                "aria-invalid": _vm.validator.has(_vm.inputName("pw"))
                  ? "true"
                  : null,
              },
              domProps: { value: _vm.pw },
              on: {
                keypress: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "escape",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.CloseDialog.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.login.apply(null, arguments)
                  },
                ],
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.pw = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.validator.has(_vm.inputName("pw")),
                    expression: "validator.has(inputName('pw'))",
                  },
                ],
                staticClass: "validationWarning",
                attrs: { id: "profilePwEmailBoxValid" },
              },
              [_vm._v(_vm._s(_vm.validator.first(_vm.inputName("pw")).msg))]
            ),
          ]),
          _vm._v(" "),
          !_vm.isInPopup
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "rememberMeBox" },
                  [
                    _c(
                      "SRCustomCheckbox",
                      {
                        attrs: { srtabindex: "0", idprefix: "profileLoginCB" },
                        on: {
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "escape",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.CloseDialog.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.rememberMe,
                          callback: function ($$v) {
                            _vm.rememberMe = $$v
                          },
                          expression: "rememberMe",
                        },
                      },
                      [_vm._v("Remember Me (uses cookies)")]
                    ),
                  ],
                  1
                ),
              ])
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "rememberMeBoxAbsPos" },
                  [
                    _c(
                      "SRCustomCheckbox",
                      {
                        attrs: { srtabindex: "0", idprefix: "profileLoginCB" },
                        on: {
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "escape",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.CloseDialog.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.rememberMe,
                          callback: function ($$v) {
                            _vm.rememberMe = $$v
                          },
                          expression: "rememberMe",
                        },
                      },
                      [_vm._v("Remember Me (uses cookies)")]
                    ),
                  ],
                  1
                ),
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "loginRegisterButtons" }, [
            _c(
              "div",
              {
                staticClass: "loginButton",
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: _vm.login,
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "escape",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.CloseDialog.apply(null, arguments)
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.login.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tLog in\n\t\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "registerBtn",
                staticClass: "registerButton",
                attrs: {
                  role: "button",
                  "aria-haspopup": "dialog",
                  tabindex: "0",
                },
                on: {
                  click: _vm.register,
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "escape",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.CloseDialog.apply(null, arguments)
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.register.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tRegister\n\t\t\t\t")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "smallLinks" }, [
          _c(
            "a",
            {
              staticClass: "alwaysUnvisited",
              attrs: {
                role: "button",
                tabindex: "0",
                "aria-haspopup": "dialog",
              },
              on: {
                click: _vm.ForgotPassword,
                keypress: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "escape",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.CloseDialog.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "enter",
                        13,
                        $event.key,
                        "Enter"
                      ) &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.ForgotPassword.apply(null, arguments)
                  },
                ],
              },
            },
            [_vm._v("Forgot Password?")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("FullScreenLoadingMsg", {
        attrs: {
          show: _vm.loggingIn,
          msg: "Logging In",
          backgroundColor: "white",
          shadow: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }