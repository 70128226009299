var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Transition",
    {
      attrs: {
        name: _vm.isShowingLeftPanel
          ? "twoPanelSlideRight"
          : "twoPanelSlideLeft",
      },
      on: {
        "before-enter": _vm.TransitionStart,
        "after-leave": _vm.TransitionEnd,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }