var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      ref: _vm.reference,
      class: _vm.computedClass,
      attrs: { tabindex: "0", id: _vm.id || _vm._uid },
      on: {
        click: _vm.click,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "space", 32, $event.key, [
              " ",
              "Spacebar",
            ]) &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          $event.preventDefault()
          return _vm.click.apply(null, arguments)
        },
      },
    },
    [
      _c("div", {
        staticStyle: { height: "0px", width: "0px", border: "none" },
        attrs: { id: _vm.captchaDivId },
        on: { focusin: _vm.Focused, focusout: _vm.Unfocused },
      }),
      _vm._v(" "),
      _c("div", [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }