var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "minorErrorRoot",
      attrs: {
        role: "dialog",
        "aria-labelledby": "minorErrorTitle",
        "aria-describedby": "minorErrorLabel",
      },
    },
    [
      _c("div", { staticClass: "titleBar" }, [
        _c(
          "div",
          {
            ref: "closeBtn",
            staticClass: "closeButton",
            attrs: {
              tabindex: "0",
              role: "button",
              "aria-label": "close",
              title: "close",
            },
            on: {
              click: _vm.DefaultClose,
              keypress: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                ) {
                  return null
                }
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.DefaultClose.apply(null, arguments)
              },
            },
          },
          [
            _c("vsvg", {
              attrs: {
                sprite: "#x",
                title: "",
                desc: "",
                role: "presentation",
                "aria-hidden": "true",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialogBody",
          attrs: { id: "minorErrorLabel", role: "document" },
        },
        [
          _c(
            "ul",
            _vm._l(_vm.errors, function (error) {
              return _c("li", [_vm._v(_vm._s(error))])
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "title",
        attrs: { id: "minorErrorTitle", role: "heading" },
      },
      [
        _vm._v("Recent Errors "),
        _c("span", { staticClass: "visuallyhidden" }, [
          _vm._v("Press escape to close."),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }