var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mfaPasskeySection" }, [
    _vm.isLoginChallenge
      ? _c("div", { staticClass: "body" }, [
          !_vm.passkeySupported
            ? _c("div", { staticClass: "passkeySectionDescription" }, [
                _vm._v("This web browser does not support passkeys."),
              ])
            : _vm.isAssertionPending
            ? _c("div", { staticClass: "passkeySectionDescription" }, [
                _c("p", [
                  _vm._v(
                    "Your device should be asking you to authenticate with a passkey."
                  ),
                ]),
                _vm._v(" "),
                _c("div", [_c("ScaleLoader", { staticClass: "animation" })], 1),
              ])
            : _vm.assertionError
            ? _c("div", { staticClass: "passkeySectionDescription" }, [
                _vm._v(_vm._s(_vm.assertionError)),
              ])
            : _vm.passkeyAssertionCompleted
            ? _c(
                "div",
                { staticClass: "passkeySectionDescription" },
                [
                  _c("p", [_vm._v("Logging in with passkey…")]),
                  _vm._v(" "),
                  _vm.assertingPasskey
                    ? _c("MFAPasskeyRecord", {
                        attrs: { passkey: _vm.assertingPasskey },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    [_c("ScaleLoader", { staticClass: "animation" })],
                    1
                  ),
                ],
                1
              )
            : _vm.assertionObject
            ? _c(
                "div",
                { staticClass: "passkeySectionDescription" },
                [
                  _c("div", { staticClass: "passkeySectionDescription" }),
                  _vm._v(" "),
                  _c("p", [_vm._v("Ready to submit login with passkey:")]),
                  _vm._v(" "),
                  _vm.assertingPasskey
                    ? _c("MFAPasskeyRecord", {
                        attrs: { passkey: _vm.assertingPasskey },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.canRememberDevice
                    ? _c(
                        "div",
                        {
                          staticClass: "rightAlignContent",
                          staticStyle: { margin: "1em 0px" },
                        },
                        [
                          _c(
                            "SRCustomCheckbox",
                            {
                              attrs: { square: true, size: 16 },
                              model: {
                                value: _vm.rememberDevice,
                                callback: function ($$v) {
                                  _vm.rememberDevice = $$v
                                },
                                expression: "rememberDevice",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\tRemember this device\n\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "medButton primaryColorButton fullWidthButton",
                      attrs: { tabindex: "0" },
                      on: {
                        click: function ($event) {
                          return _vm.SubmitAssertion()
                        },
                      },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "passkeySectionDescription" }, [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.appNameHtml) } }),
            _vm._v(" allows a "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://fidoalliance.org/passkeys/",
                  target: "_blank",
                },
              },
              [_vm._v("passkey")]
            ),
            _vm._v(" to be used as a Multi-Factor Authentication method."),
          ]),
          _vm._v(" "),
          _vm.passkeys.length === 0
            ? _c("div", [
                _vm._v(
                  "\n\t\t\tYour user account does not have any passkeys.\n\t\t"
                ),
              ])
            : _c(
                "div",
                { class: { passkeyList: true, isDeleting: _vm.isDeleting } },
                [
                  _c("div", { staticClass: "passkeyListHeader" }, [
                    _vm._v(
                      "\n\t\t\t\tYou have " +
                        _vm._s(_vm.passkeys.length) +
                        " passkey" +
                        _vm._s(_vm.passkeys.length === 1 ? "" : "s") +
                        ":\n\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.passkeys, function (passkey) {
                    return _c("MFAPasskeyRecord", {
                      key: passkey.Id,
                      attrs: {
                        passkey: passkey,
                        editable: true,
                        disableButtons: _vm.disableButtons,
                        UN: _vm.UN,
                        PW: _vm.PW,
                      },
                      on: {
                        delete: function ($event) {
                          return _vm.DeletePasskey(passkey)
                        },
                      },
                    })
                  }),
                ],
                2
              ),
          _vm._v(" "),
          _vm.passkeySupported
            ? _c("div", { staticClass: "addPasskeySection" }, [
                _c(
                  "button",
                  {
                    staticClass: "medButton primaryColorButton fullWidthButton",
                    attrs: { tabindex: "0" },
                    on: { click: _vm.CreatePasskey },
                  },
                  [_vm._v("Create passkey")]
                ),
              ])
            : _c("div", [
                _vm._v(
                  "\n\t\t\tThis web browser does not support passkeys.\n\t\t"
                ),
              ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }