<template>
	<div :class="{ inputDialogRoot: true, shake: shake }" ref="dialogRoot" role="dialog" aria-labelledby="textInputMsgTitle" :aria-describedby="message ? 'textInputMsg' : 'textInputMsgTitle'">
		<div v-if="title" class="titleBar">
			<div id="textInputMsgTitle" class="title" role="alert">{{title}} <span class="visuallyhidden">Press escape to cancel.</span></div>
		</div>
		<div class="dialogBody">
			<div class="inputWrapper">
				<label class="labelWrapper">
					<span id="textInputMsg" class="label" v-if="message">{{message}} <span class="visuallyhidden">Press escape to cancel.</span></span>
					<input type="text" ref="inputText" :placeholder="placeholder" v-model:value="value"
						   @keypress.enter="okClicked" v-bind:maxlength="maxTextLength" class="textInput" @blur="onInputBlur" />
				</label>
			</div>
			<div class="inputWrapper checkbox" v-if="checkboxText">
				<SRCustomCheckbox v-model="checked" :square="true" :size="15" fontSize="11pt">
					{{checkboxText}}
				</SRCustomCheckbox>
			</div>
		</div>
		<div class="buttons">
			<div ref="okBtn" role="button" class="dialogButton okButton" tabindex="0" @click="okClicked" @keydown.space.enter.prevent="okClicked" @keydown.right.prevent="FocusCancel">
				{{okButtonText ? okButtonText : "OK"}}
			</div>
			<div v-if="cancelButtonText" ref="cancelBtn" role="button" class="dialogButton cancelButton" tabindex="0" @click="cancelClicked" @keydown.space.enter.prevent="cancelClicked" @keydown.left.prevent="FocusOK">
				{{cancelButtonText}}
			</div>
		</div>
	</div>
</template>

<script>
	let animationEvents = ["webkitAnimationEnd", "oanimationend", "msAnimationEnd", "animationend"];
	import SRCustomCheckbox from "tdsAppRoot/components/Controls/SRCustomCheckbox.vue";

	export default {
		components: { SRCustomCheckbox },
		props:
		{
			title: {
				type: String,
				default: null
			},
			message: {
				type: String,
				default: ""
			},
			placeholder: {
				type: String,
				default: ""
			},
			initialText: {
				type: String,
				default: ""
			},
			maxTextLength: {
				type: Number,
				default: 4096
			},
			checkboxText: {  // If not null, a checkbox will appear labeled with this text.  If not null, the returned value from this dialog becomes an object containing a string and a bool, rather than just a string.
				type: String,
				default: null
			},
			allowEmptyInput: {
				type: Boolean,
				default: false
			},
			okButtonText: {
				type: String,
				default: "OK"
			},
			cancelButtonText: {
				type: String,
				default: "Cancel"
			},
		},
		data()
		{
			return {
				value: "",
				shake: false,
				checked: false
			};
		},
		mounted()
		{
			this.value = " ";
			this.$nextTick(() =>
			{
				// Necessary to work around a glitch where iOS 10 displays the placeholder and the text on top of eachother.
				if (this.initialText)
					this.value = this.initialText;
				else
					this.value = "";

				if (this.$refs.inputText)
					this.$refs.inputText.focus();
				this.$nextTick(() =>
				{
					if (this.$refs.inputText)
						this.$refs.inputText.setSelectionRange(0, this.$refs.inputText.value.length); // select all text so the user can simply begin typing
				});
			});
		},
		methods:
		{
			SetFocus()
			{
				if (this.$refs.inputText)
					this.$refs.inputText.focus();
			},
			okClicked()
			{
				if (this.value || this.allowEmptyInput)
				{
					if (this.checkboxText)
						this.$emit("close", { value: this.value, checked: this.checked });
					else
						this.$emit("close", { value: this.value });
				}
				else
				{
					this.startShaking();
				}
			},
			cancelClicked()
			{
				this.$emit("close");
			},
			startShaking()
			{
				if (this.shake)
					return;
				animationEvents.forEach(event =>
				{
					if (this.$refs.dialogRoot)
						this.$refs.dialogRoot.addEventListener(event, this.animationEnd);
				});
				this.shake = true;
			},
			animationEnd(e)
			{
				this.shake = false;
				animationEvents.forEach(event =>
				{
					if (this.$refs.dialogRoot)
						this.$refs.dialogRoot.removeEventListener(event, this.animationEnd);
				});
			},
			FocusOK()
			{
				this.$refs.okBtn.focus();
			},
			FocusCancel()
			{
				if (this.$refs.cancelBtn)
					this.$refs.cancelBtn.focus();
			},
			onInputBlur()
			{
				// On dialog load, we automatically select all text in the text input.
				// However in Chrome, this makes it impossible to unselect the text using the mouse, which is very frustrating if all you wanted to do was make a minor edit to the text.
				// So this function gets called when the input loses focus, causing the text to get unselected if it was 100% selected.  That way you can click anywhere (e.g. on the label) to cause the text to be unselected.
				const it = this.$refs.inputText;
				if (it)
				{
					const isFullySelected = it.selectionStart === 0 && it.selectionEnd === it.value.length;
					if (isFullySelected)
						it.setSelectionRange(it.value.length, it.value.length); // unselect all text, move input cursor to end

				}
			}
		}
	}
</script>

<style scoped>
	.inputDialogRoot
	{
		max-width: 300px;
		background-color: #FFFFFF;
	}

	.checkbox
	{
		font-size: 15px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 5px;
	}

		.checkbox label
		{
			font-family: 'Open Sans', sans serif;
		}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}


	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.labelWrapper
	{
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.label
	{
		text-align: left;
		margin: 0.5em 5px;
	}

	.dialogBody
	{
		padding: 0px 1em;
	}

	.inputWrapper
	{
		display: flex;
	}

	.shake .textInput
	{
		outline: 3px solid #FF0000;
	}

	.textInput, .checkBoxInput
	{
		flex: 1 1 auto;
		margin-bottom: 10px;
		border: 1px solid #AAAAAA;
		border-radius: 3px;
		padding: 3px 6px;
		font-size: 13pt;
	}

		.textInput:focus-visible
		{
			border: 2px solid black;
			padding: 2px 5px;
			outline: none;
		}

		.textInput.focus-visible
		{
			border: 2px solid black;
			padding: 2px 5px;
			outline: none;
		}

		.checkBoxInput:focus-visible
		{
			outline: 2px solid black;
			border-radius: 5px;
		}

		.checkBoxInput.focus-visible
		{
			outline: 2px solid black;
			border-radius: 5px;
		}

	.buttons
	{
		display: flex;
	}

	.dialogButton
	{
		display: inline-block;
		cursor: pointer;
		color: black;
		font-weight: bold;
		font-size: 12pt;
		box-sizing: border-box;
		position: relative;
		padding: 12px 5px;
		flex: 1 0 auto;
		text-align: center;
	}

		.dialogButton:focus-visible
		{
			border: 2px solid black;
			padding: 10px 3px;
			outline: none;
		}

		.dialogButton.focus-visible
		{
			border: 2px solid black;
			padding: 10px 3px;
			outline: none;
		}

		.dialogButton:hover
		{
			background-color: rgba(0,0,0,0.05);
		}

	.okButton
	{
		color: #3D8538;
	}

	.cancelButton
	{
		color: #A66621;
		border-left: 1px solid #DDDDDD;
	}

	.shake
	{
		-webkit-animation: kf_shake 0.4s 1 linear;
		-moz-animation: kf_shake 0.4s 1 linear;
		-o-animation: kf_shake 0.4s 1 linear;
		animation: kf_shake 0.4s linear 0 1;
	}

	@-webkit-keyframes kf_shake
	{
		0%
		{
			-webkit-transform: translate(30px);
		}

		20%
		{
			-webkit-transform: translate(-30px);
		}

		40%
		{
			-webkit-transform: translate(15px);
		}

		60%
		{
			-webkit-transform: translate(-15px);
		}

		80%
		{
			-webkit-transform: translate(8px);
		}

		100%
		{
			-webkit-transform: translate(0px);
		}
	}

	@-moz-keyframes kf_shake
	{
		0%
		{
			-moz-transform: translate(30px);
		}

		20%
		{
			-moz-transform: translate(-30px);
		}

		40%
		{
			-moz-transform: translate(15px);
		}

		60%
		{
			-moz-transform: translate(-15px);
		}

		80%
		{
			-moz-transform: translate(8px);
		}

		100%
		{
			-moz-transform: translate(0px);
		}
	}

	@-o-keyframes kf_shake
	{
		0%
		{
			-o-transform: translate(30px);
		}

		20%
		{
			-o-transform: translate(-30px);
		}

		40%
		{
			-o-transform: translate(15px);
		}

		60%
		{
			-o-transform: translate(-15px);
		}

		80%
		{
			-o-transform: translate(8px);
		}

		100%
		{
			-o-origin-transform: translate(0px);
		}
	}

	@keyframes kf_shake
	{
		0%
		{
			transform: translate(30px);
		}

		20%
		{
			transform: translate(-30px);
		}

		40%
		{
			transform: translate(15px);
		}

		60%
		{
			transform: translate(-15px);
		}

		80%
		{
			transform: translate(8px);
		}

		100%
		{
			transform: translate(0px);
		}
	}
</style>