<template>
	<div class="codeEntry">
		<label>
			Enter the {{codeLabelString}}{{codeFromString}}:
			<div>
				<input type="text" pattern="[0-9]*" inputmode="numeric" v-model="otpInput" class="otpInput" :placeholder="codeLabelString" ref="otpInputEle" v-on:keypress="KeyPress" autocomplete="one-time-code" />
			</div>
		</label>
		<div class="rightAlignContent" style="margin: 1em 0px;" v-if="canRememberDevice && method.Configured">
			<SRCustomCheckbox v-model="rememberDevice" :square="true" :size="16">
				Remember this device
			</SRCustomCheckbox>
		</div>
		<div v-if="method.ID === 2" style="margin: 1em 0px;">
			Can't find it? <a role="button" class="alwaysUnvisited" @click="ResendEmailCode()" @keydown.enter.space.stop.prevent="ResendEmailCode()" tabindex="0">Click here to resend the email.</a>
		</div>
		<div class="rightAlignContent" style="margin-top: 1.25em;">
			<button class="medButton greenHighButton submitButton" @click="SubmitOTPCode()" tabindex="0" :disabled="!submitEnabled">{{ method.Configured ? 'Submit' : 'Enable MFA Method' }}</button>
		</div>
		<!--<div class="centerContent codeLengthMessage" style="margin-top: 1.25em;" v-if="inputTooShort">
			(Code is too short)
		</div>-->
		<div class="centerContent codeLengthMessage" style="margin-top: 1.25em;" v-if="inputTooLong">
			(Code is too long)
		</div>
	</div>
</template>

<script>
	import SRCustomCheckbox from "tdsAppRoot/components/Controls/SRCustomCheckbox.vue";

	export default {
		components: { SRCustomCheckbox },
		props:
		{
			method: {
				type: Object,
				required: true
			},
			canRememberDevice: {
				type: Boolean,
				required: true
			},
		},
		data()
		{
			return {
				otpInput: "",
				rememberDevice: false,
			};
		},
		created()
		{
		},
		mounted()
		{
		},
		computed:
		{
			codeLabelString()
			{
				if (this.method.OtpDigits)
					return this.method.OtpDigits + "-digit code";
				return "code";
			},
			codeFromString()
			{
				if (this.method.ID === 1)
					return " from your Authenticator app";
				else if (this.method.ID === 2)
					return " we sent to your email";
				return "";
			},
			submitEnabled()
			{
				return !this.method.OtpDigits || this.otpInput.trim().length === this.method.OtpDigits;
			},
			inputTooShort()
			{
				return this.method.OtpDigits && this.otpInput.trim().length && this.otpInput.trim().length < this.method.OtpDigits;
			},
			inputTooLong()
			{
				return this.method.OtpDigits && this.otpInput.trim().length > this.method.OtpDigits;
			}
		},
		methods:
		{
			KeyPress(event)
			{
				if (event.keyCode === 13 || event.key === "enter")
					this.SubmitOTPCode();
			},
			focus()
			{
				if (this.$refs.otpInputEle)
					this.$refs.otpInputEle.focus();
			},
			SubmitOTPCode()
			{
				this.$emit("submit", {
					code: this.otpInput.trim(),
					rememberDevice: this.rememberDevice
				});
			},
			ResendEmailCode()
			{
				this.$emit("resendemail");
			}
		},
		watch:
		{
			otpInput()
			{
				// ENABLE THIS WATCH ONLY IF USING AN input type="text". Input type="number" is not compatible with this.
				// Prevent entry of disallowed characters without noticeably affecting input cursor position.
				let selectionStart = this.otpInput.length;
				let selectionEnd = this.otpInput.length;
				if (this.$refs.otpInputEle)
				{
					selectionStart = this.$refs.otpInputEle.selectionStart;
					selectionEnd = this.$refs.otpInputEle.selectionEnd;
				}

				let clean = "";
				for (let i = 0; i < this.otpInput.length; i++)
				{
					if (i === selectionStart)
						selectionStart = clean.length;
					if (i === selectionEnd)
						selectionEnd = clean.length;
					let c = this.otpInput.charAt(i);
					if (c >= '0' && c <= '9')
						clean += c;
				}
				// Trimming to max length is inconvenient if the user makes a typo and is trying to correct it.
				//if (this.method.OtpDigits && clean.length > this.method.OtpDigits)
				//{
				//	clean = clean.substr(0, this.inputMaxLength);
				//	selectionStart = Math.min(selectionStart, clean.length - 1);
				//	selectionEnd = Math.min(selectionEnd, clean.length - 1);
				//}
				if (clean != this.otpInput)
				{
					this.otpInput = clean;
					if (this.$refs.otpInputEle)
					{
						this.$nextTick(() =>
						{
							this.$refs.otpInputEle.selectionStart = selectionStart;
							this.$refs.otpInputEle.selectionEnd = selectionEnd;
						});
					}
				}
			}
		}
	}
</script>

<style scoped>
	.rightAlignContent
	{
		display: flex;
		justify-content: flex-end;
	}

	.centerContent
	{
		display: flex;
		justify-content: center;
	}

	.codeLengthMessage
	{
		font-style: italic;
	}

	.otpInput
	{
		margin-top: 0.5em;
		border: 1px solid #AAAAAA;
		border-radius: 3px;
		padding: 3px 6px;
		font-size: 24pt;
		width: 100%;
		text-align: center;
	}

	.submitButton
	{
		width: 100%;
	}
</style>