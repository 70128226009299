var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mySRRegistrationRoot",
      attrs: {
        "aria-describedby": "regdesc",
        role: "dialog",
        id: "mySRRegistrationRoot",
        "aria-label":
          "Personalized Profile Account registration form.  Sign up in seconds.  Press escape to close.",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { display: "none" }, attrs: { id: "regdesc" } },
        [
          _vm._v(
            "\n\t\tPersonalized Profile Account registration form.  Sign up in seconds.  Press escape to close.\n\t"
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "leftSide" }, [
        _c("div", { staticClass: "signUpTitle" }, [
          _vm._v("\n\t\t\tSign up in seconds\n\t\t"),
        ]),
        _vm._v(" "),
        _c("div", { ref: "validateForm", staticClass: "inputs" }, [
          _c("div", { staticClass: "firstLastNameInputCtrls" }, [
            _c("div", { staticClass: "inputCtrls firstNameInputCtrls" }, [
              _c("label", { attrs: { for: "preg_firstName" } }, [
                _vm._v("* First Name"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.validator.has("preg_firstName"),
                      expression: "validator.has('preg_firstName')",
                    },
                  ],
                  staticClass: "validationWarning",
                  attrs: { id: "preg_firstNameValid" },
                },
                [_vm._v(_vm._s(_vm.validator.first("preg_firstName").msg))]
              ),
              _c("br"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.firstName,
                    expression: "firstName",
                  },
                ],
                ref: "firstName",
                class: { invalid: _vm.validator.has("preg_firstName") },
                attrs: {
                  name: "preg_firstName",
                  id: "preg_firstName",
                  type: "text",
                  "data-vv-as": "First Name",
                  maxlength: "20",
                  validatespec: "required|max:20",
                  "data-lpignore": "true",
                  autocomplete: "given-name",
                  "aria-describedby": "preg_firstNameValid",
                  "aria-invalid": _vm.validator.has("preg_firstName")
                    ? "true"
                    : null,
                },
                domProps: { value: _vm.firstName },
                on: {
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "escape",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.DefaultClose.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.firstName = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "inputCtrls lastNameInputCtrls" }, [
              _c("label", { attrs: { for: "preg_lastName" } }, [
                _vm._v("Last Name"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.validator.has("preg_lastName"),
                      expression: "validator.has('preg_lastName')",
                    },
                  ],
                  staticClass: "validationWarning",
                  attrs: { id: "preg_lastNameValid" },
                },
                [_vm._v(_vm._s(_vm.validator.first("preg_lastName").msg))]
              ),
              _c("br"),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.lastName,
                    expression: "lastName",
                    modifiers: { trim: true },
                  },
                ],
                class: { invalid: _vm.validator.has("preg_lastName") },
                attrs: {
                  name: "preg_lastName",
                  id: "preg_lastName",
                  type: "text",
                  "data-vv-as": "Last Name",
                  maxlength: "20",
                  validatespec: "max:20",
                  "data-lpignore": "true",
                  autocomplete: "family-name",
                  "aria-describedby": "preg_lastNameValid",
                  "aria-invalid": _vm.validator.has("preg_lastName")
                    ? "true"
                    : null,
                },
                domProps: { value: _vm.lastName },
                on: {
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "escape",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.DefaultClose.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.lastName = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "inputCtrls emailInputCtrls" }, [
            _c("label", { attrs: { for: "preg_email" } }, [_vm._v("* Email")]),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.validator.has("preg_email"),
                    expression: "validator.has('preg_email')",
                  },
                ],
                staticClass: "validationWarning",
                attrs: { id: "preg_emailValid" },
              },
              [_vm._v(_vm._s(_vm.validator.first("preg_email").msg))]
            ),
            _c("br"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.email,
                  expression: "email",
                  modifiers: { trim: true },
                },
              ],
              class: { invalid: _vm.validator.has("preg_email") },
              attrs: {
                name: "preg_email",
                id: "preg_email",
                type: "text",
                "data-vv-as": "Email",
                maxlength: "250",
                validatespec: "required|email|max:250",
                autocomplete: "username",
                "aria-describedby": "preg_emailValid",
                "aria-invalid": _vm.validator.has("preg_email") ? "true" : null,
              },
              domProps: { value: _vm.email },
              on: {
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k(
                      $event.keyCode,
                      "escape",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.DefaultClose.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value.trim()
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "inputCtrls passwordInputCtrls" }, [
            _c("label", { attrs: { for: "preg_pw" } }, [_vm._v("* Password")]),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.validator.has("preg_pw"),
                    expression: "validator.has('preg_pw')",
                  },
                ],
                staticClass: "validationWarning",
                attrs: { id: "preg_pwValid" },
              },
              [_vm._v(_vm._s(_vm.validator.first("preg_pw").msg))]
            ),
            _c("br"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pw,
                  expression: "pw",
                },
              ],
              class: { invalid: _vm.validator.has("preg_pw") },
              attrs: {
                name: "preg_pw",
                id: "preg_pw",
                type: "password",
                "aria-label": "Password",
                placeholder: "At least 8 characters",
                "data-vv-as": "Password",
                maxlength: "40",
                validatespec: "required|min:5|max:40",
                autocomplete: "new-password",
                "aria-describedby": "preg_pwValid",
                "aria-invalid": _vm.validator.has("preg_pw") ? "true" : null,
              },
              domProps: { value: _vm.pw },
              on: {
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k(
                      $event.keyCode,
                      "escape",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.DefaultClose.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.pw = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "inputCtrls passwordInputCtrls" }, [
            _c("label", { attrs: { for: "preg_rpw" } }, [
              _vm._v("* Repeat Password"),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.validator.has("preg_rpw"),
                    expression: "validator.has('preg_rpw')",
                  },
                ],
                staticClass: "validationWarning",
                attrs: { id: "preg_rpwValid" },
              },
              [_vm._v(_vm._s(_vm.validator.first("preg_rpw").msg))]
            ),
            _c("br"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.rpw,
                  expression: "rpw",
                },
              ],
              class: { invalid: _vm.validator.has("preg_rpw") },
              attrs: {
                name: "preg_rpw",
                id: "preg_rpw",
                type: "password",
                "aria-label": "Repeat Password",
                placeholder: "At least 8 characters",
                "data-vv-as": "Repeat Password",
                maxlength: "40",
                validatespec: "required|min:5|confirmed:preg_pw|max:40",
                autocomplete: "new-password",
                "aria-describedby": "preg_rpwValid",
                "aria-invalid": _vm.validator.has("preg_rpw") ? "true" : null,
              },
              domProps: { value: _vm.rpw },
              on: {
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k(
                      $event.keyCode,
                      "escape",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.DefaultClose.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.rpw = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "rememberMeBox" },
            [
              _c(
                "SRCustomCheckbox",
                {
                  attrs: {
                    id: "rememberCheckBox",
                    idprefix: "profileRegCB",
                    square: true,
                  },
                  model: {
                    value: _vm.remember,
                    callback: function ($$v) {
                      _vm.remember = $$v
                    },
                    expression: "remember",
                  },
                },
                [_vm._v("Remember me on this computer (uses cookies)")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "termsNotice" }, [
            _vm._v(
              '\n\t\t\t\tBy clicking "Register Now" below, you are agreeing to the TDS '
            ),
            _c(
              "a",
              {
                attrs: {
                  role: "button",
                  tabindex: "0",
                  "aria-haspopup": "dialog",
                },
                on: {
                  keypress: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "escape",
                          undefined,
                          $event.key,
                          undefined
                        )
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.DefaultClose.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "enter",
                          13,
                          $event.key,
                          "Enter"
                        ) &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ])
                      ) {
                        return null
                      }
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.ShowEulaPopup.apply(null, arguments)
                    },
                  ],
                  click: _vm.ShowEulaPopup,
                },
              },
              [_vm._v("Terms and Conditions")]
            ),
            _vm._v(" and "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.tetondata.com/privacy.cshtml",
                  target: "_blank",
                },
              },
              [_vm._v("Privacy Policy")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "RegisterBtnWrapper" },
            [
              _c(
                "invisible-recaptcha",
                {
                  ref: "captchaComponent",
                  staticClass: "registerBtnBox",
                  attrs: {
                    sitekey: "6LfrjUsUAAAAAIhDbhKQAtBYk4uJ2o8bkpiYIWu3",
                    validate: _vm.OnValidate,
                    callback: _vm.RegisterNow,
                    type: "submit",
                    id: "registerBtn",
                    disabled: false,
                    "aria-label": "Register Now",
                  },
                },
                [_vm._v("\n\t\t\t\t\tRegister Now\n\t\t\t\t")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "rightSide" }, [
        _c(
          "div",
          {
            staticClass: "modalDialogCloseButton",
            attrs: { tabindex: "0", role: "button", "aria-label": "Close" },
            on: {
              click: _vm.DefaultClose,
              keypress: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                ) {
                  return null
                }
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.DefaultClose.apply(null, arguments)
              },
            },
          },
          [
            _c("vsvg", {
              attrs: {
                sprite: "#x",
                title: "",
                desc: "",
                role: "presentation",
                "aria-hidden": "true",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bigLogoBox" },
          [
            _c("vsvg", {
              staticClass: "bigLogo",
              attrs: {
                sprite: "#bookshelf_white",
                role: "presentation",
                "aria-hidden": "true",
                title: "",
                desc: "",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "bigLogoCaption" }, [
              _vm._v("Personalized Profile"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "whatYouGet" }, [
          _c("div", { staticClass: "wygTitle" }, [
            _vm._v("\n\t\t\t\tWhat you get\n\t\t\t"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wygItem" }, [
            _vm._v(
              "\n\t\t\t\tAutomatically Sign On across all of your favorite " +
                _vm._s(_vm.appContext.appName) +
                " products\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wygItem" }, [
            _vm._v(
              "\n\t\t\t\tCustomize views and personalize your homepage\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wygItem" }, [
            _vm._v(
              "\n\t\t\t\tAccess " +
                _vm._s(_vm.appContext.appName) +
                " from anywhere\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wygItem" }, [
            _vm._v("\n\t\t\t\tSave search history and results\n\t\t\t"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wygItem" }, [
            _vm._v(
              "\n\t\t\t\tSave and annotate specific paragraphs in " +
                _vm._s(_vm.subProduct) +
                " titles\n\t\t\t"
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }