var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "passkeyRecord", attrs: { tabindex: "0" } }, [
    _c(
      "div",
      { staticClass: "passkeyTitlebar" },
      [
        _c("vsvg", {
          staticClass: "passkeyIcon",
          attrs: { sprite: "#FIDO_Passkey_mark_A_black", title: "", desc: "" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "passkeyName" }, [
          _vm._v(
            _vm._s(
              _vm.passkey.Description
                ? _vm.passkey.Description
                : "Unnamed passkey"
            )
          ),
        ]),
        _vm._v(" "),
        _vm.editable
          ? [
              _c("SvgButton", {
                staticClass: "passkeyButton",
                attrs: {
                  sprite: "#rename",
                  title: "Rename passkey",
                  desc: "",
                  disabled: _vm.disableMyButtons,
                },
                on: { click: _vm.RenamePasskey },
              }),
              _vm._v(" "),
              _c("SvgButton", {
                staticClass: "passkeyButton",
                attrs: {
                  sprite: "#delete_forever",
                  title: "Delete passkey",
                  desc: "",
                  disabled: _vm.disableMyButtons,
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("delete", _vm.passkey)
                  },
                },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "passkeyField passkeyId",
        attrs: { title: "Passkey credential ID: " + _vm.passkey.Id },
      },
      [
        _c("b", [_vm._v("Credential ID:")]),
        _vm._v(" " + _vm._s(_vm.passkey.Id)),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "passkeyField" }, [
      _c("b", [_vm._v("Created:")]),
      _vm._v(" " + _vm._s(_vm.DateStr(_vm.passkey.RegDate))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "passkeyField" }, [
      _c("b", [_vm._v("Last used:")]),
      _vm._v(" " + _vm._s(_vm.DateStr(_vm.passkey.LastUsed))),
    ]),
    _vm._v(" "),
    _vm.busy
      ? _c("div", { staticClass: "passkeyBusy" }, [
          _c(
            "div",
            { staticClass: "passkeyBusyContent" },
            [_c("ScaleLoader", { staticClass: "animation" })],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }