import { CallServer } from "tdsAppRoot/API/apibase.js";
import { EncodedHost } from "tdsAppRoot/library/TDSUtil.js";

// Mirrors BiblioRequest on server
var SaveFavoriteRequest = function ()
{
	var docAddress;
	var type;
	var para;
	var text;	// User's annotation.
	var docDate;
	var fxHash;
	var notePk;
	var context;
	var path;
	var disableDateCheck;
	var searchArgs; // If a search favorite.
	var highlightStartOffset;
	var highlightLength;
	var highlightText;
	var color;
};
Object.seal(SaveFavoriteRequest);

var FavIdRequest = function ()
{
	var favid;
};
Object.seal(FavIdRequest);

var SaveHighlightRequest = function ()
{
	var startOffset;
	var length;
};
Object.seal(SaveHighlightRequest);

//var ResolveFavIdRequest = function ()
//{
//	var favid;
//};
//Object.seal(ResolveFavIdRequest);

//var DeleteFavoriteRequest = function ()
//{
//	var favid;
//};
//Object.seal(DeleteFavoriteRequest);

var SendFavRequest = function ()
{
	var favid;
	var message;
	var to;
	var encodedHost;
};
Object.seal(SendFavRequest);

var DeleteListOfFavoritesRequest = function ()
{
	var favidlist;
}
Object.seal(DeleteListOfFavoritesRequest);

var DeleteAllFavsRequest = function ()
{
	var annotations = false;
};
Object.seal(DeleteAllFavsRequest);


var ConvertFavoriteRequest = function ()
{
	var favid;
	var bAnnotations;
};
Object.seal(ConvertFavoriteRequest);

/// type: 'doc', 'anondoc', 'src', or 'anonsrch'
var SaveFavorite = function (store, type, docAddress, text, context, docDate, fxHash, paraId, favid, path, highlightStartOffset, highlightLength, highlightText, disableDateCheck = false, searchArgs = null, color = null)
{
	try
	{
		let myargs = new SaveFavoriteRequest();
		myargs.docAddress = docAddress;
		myargs.type = type;
		myargs.text = text;
		myargs.context = context;
		myargs.docDate = docDate;
		myargs.fxHash = fxHash;
		myargs.favid = favid;
		myargs.path = path;
		myargs.para = paraId;
		myargs.disableDateCheck = disableDateCheck;
		myargs.searchArgs = searchArgs;
		myargs.highlightStartOffset = highlightStartOffset;
		myargs.highlightLength = highlightLength;
		myargs.highlightText = highlightText;
		myargs.color = color;

		return CallServer("Favorites", "SaveFavorite", myargs, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
};

var GetFavorites = function (store)
{
	try
	{
		return CallServer("Favorites", "GetFavorites", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

var DeleteFavorite = function (store, favid)
{
	try
	{
		let args = new FavIdRequest();
		args.favid = favid;
		return CallServer("Favorites", "DeleteFavorite", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

var DeleteListOfFavorites = function (store, list)
{
	try
	{
		let args = new DeleteListOfFavoritesRequest();
		args.favidlist = list;
		return CallServer("Favorites", "DeleteListOfFavorites", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

var DeleteAllServerFavorites = function (store, objectType)
{
	try
	{
		let args = new DeleteAllFavsRequest();
		args.objectType = objectType;

		return CallServer("Favorites", "DeleteAllFavorites", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

/**
 * Converts favorites to / from annotations.
 * @param {any} store vuex store
 * @param {any} favid which favorite to modify
 * @param {any} bAnnotation boolean.  True to convert to an annotation.
 * @returns {Promise} A promise that resolves when the network request ends.
 */
var ConvertFavorite = function (store, favid, bAnnotation)
{
	try
	{
		let args = new ConvertFavoriteRequest();
		args.favid = favid;
		args.bAnnotation = bAnnotation;

		return CallServer("Favorites", "ConvertFavorite", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

//var RemoveHighlightFromFav = function (store, favid)
//{
//	try
//	{
//		let args = new FavIdRequest();
//		args.favid = favid;

//		return CallServer("Favorites", "RemoveHighlightFromFav", args, store);
//	}
//	catch (err)
//	{
//		return Promise.reject(err);
//	}
//}

var ClearFavResolveState = function (store, favid)
{
	try
	{
		let args = new FavIdRequest();
		args.favid = favid;

		return CallServer("Favorites", "ClearFavResolveState", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

var EmailLink = function (store, favid, to, message)
{
	try
	{
		let args = new SendFavRequest();
		args.favid = favid;
		args.message = message;
		args.to = to;
		args.encodedHost = EncodedHost();
		return CallServer("Favorites", "EmailLink", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
};

/// force: Perform full resolution even if the fav is current.  No effect if not admin.
var ResolveFavId = function (store, favid, force)
{
	try
	{
		let args = new FavIdRequest();
		args.favid = favid;
		args.force = force;

		return CallServer("Favorites", "ResolveData", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
};


export { SaveFavorite, GetFavorites, DeleteFavorite, DeleteAllServerFavorites, DeleteListOfFavorites, EmailLink, ResolveFavId, ConvertFavorite, ClearFavResolveState };