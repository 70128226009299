var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { minorErrorIcon: true, show: _vm.queueLength > 0 },
      attrs: { tabindex: "0" },
      on: { click: _vm.showMinorErrorDialog },
    },
    [
      _c("vsvg", {
        staticClass: "errorIcon",
        attrs: {
          role: "presentation",
          "aria-label": _vm.queueLength + " Recent Errors",
          sprite: "#error_outline",
          title: "",
          desc: "",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "errorCount", attrs: { "aria-hidden": "true" } },
        [_vm._v(_vm._s(_vm.queueLength))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }