import { ModalMessageDialog, TextInputDialog, ProgressDialog } from 'tdsAppRoot/library/ModalDialog';
import { MFAUserBeginSetup, MFAUserEndSetup, PasskeyLoginChallenge, MFASetPasskeyDescription } from 'tdsAppRoot/API/MFAAPI';

let passkeyAbortController = null;
/**
 * Aborts the ongoing passkey operation, if any.
 */
export function cancelPasskeyOperation()
{
	if (passkeyAbortController)
	{
		try
		{
			passkeyAbortController.abort();
		}
		finally
		{
			passkeyAbortController = null;
		}
	}
}
function getPasskeyAbortSignal()
{
	if (!passkeyAbortController)
		passkeyAbortController = new AbortController();
	return passkeyAbortController.signal;
}
/**
 * Begins the passkey creation (attestation) process.
 * Returns a promise that resolves with a value of true if a passkey was created, false if not.
 * @param {Object} store Vuex store
 * @param {String} user User Name
 * @param {String} pass User account password
 * @returns
 */
export async function BeginCreatePasskey(store, user, pass)
{
	let progressDialog;
	try
	{
		progressDialog = ProgressDialog("Creating passkey");
		let response = await MFAUserBeginSetup(store, user, pass, 3);
		let options = passkey_parseCreationOptionsFromJSON(response.PasskeyCredentialOptionsJson);
		cancelPasskeyOperation();
		let credential = await navigator.credentials.create({
			publicKey: options,
			signal: getPasskeyAbortSignal(),
		});
		let attestationObject = passkey_ObjectConvertToServerFormat(credential);
		let credentialId = attestationObject.id;
		if (!credentialId)
			credentialId = attestationObject.rawId;
		progressDialog.close();
		progressDialog = null;
		let result = await TextInputDialog({
			title: "Name Your Passkey"
			, message: "(Optional) Name your passkey to recognize it more easily:"
			, placeholder: "Unnamed passkey"
			, initialText: ""
			, maxTextLength: 512
			, allowEmptyInput: true
			, cancelButtonText: ""
		});
		let passkeyName = "";
		if (result)
			passkeyName = result.value.trim();
		progressDialog = ProgressDialog("Creating passkey");
		response = await MFAUserEndSetup(store, user, pass, 3, JSON.stringify(attestationObject), arrayBufferToBase64URL(options.challenge), passkeyName);
		return true;
	}
	catch (ex)
	{
		if (ex.name === 'AbortError')
			return;
		if (ex.name === 'NotAllowedError')
			return; // User cancelled
		if (ex.name === 'OperationError')
			return; // User cancelled
		if (ex.name === 'InvalidStateError')
		{
			ModalMessageDialog("This passkey was already registered with the server.", "Passkey Created");
			return;
		}
		console.error(ex);
		ModalMessageDialog("Passkey Creation Failed: " + ex.name + ": " + ex.message, "Passkey Creation");
	}
	finally
	{
		if (progressDialog)
			progressDialog.close();
	}
	return false;
}
/**
 * Gets a passkey login challenge from the server and returns a promise that resolves with an object containing the fields necessary for the server to verify our possession of the passkey.
 * Return value: { mfaPayload: String } or { error: String, abort: Boolean }.
 * If there is any kind of failure, the promise resolves with a value of false.
 * Promise does not reject.
 * @param {Object} store Vuex store
 * @param {String} user User Name
 * @param {String} pass User account password
 * @returns
 */
export async function LoginWithPasskey(store, user, pass)
{
	// "conditional" mediation, a.k.a. autofill, is not available as we've only implemented passkeys as an MFA method instead of a primary login method.
	const isConditional = false;
	try
	{

		let response = await PasskeyLoginChallenge(store, user, pass);
		const options = passkey_parseRequestOptionsFromJSON(response.AssertionOptionsJson);
		if (!isConditional && (!options.allowCredentials || !options.allowCredentials.length))
			return { error: "This account does not have any passkeys registered.", abort: false };

		cancelPasskeyOperation();
		const credential = await navigator.credentials.get({
			publicKey: options,
			signal: getPasskeyAbortSignal(),
			mediation: isConditional ? "conditional" : undefined,
		});
		let serverCredential = passkey_ObjectConvertToServerFormat(credential);
		let passkeyCredentialId = serverCredential.id;
		if (!passkeyCredentialId)
			passkeyCredentialId = serverCredential.rawId;
		let mfaPayload = JSON.stringify({ challenge: arrayBufferToBase64URL(options.challenge), assertionResponse: serverCredential });
		return { mfaPayload, passkeyCredentialId };
	}
	catch (ex)
	{
		if (ex.name === 'AbortError')
			return { error: "Passkey authentication aborted.", abort: true };
		if (ex.name === 'NotAllowedError')
			return { error: "Passkey authentication cancelled.", abort: true }; // User cancelled
		if (ex.name === 'OperationError')
			return { error: "Passkey authentication cancelled.", abort: true }; // User cancelled
		if (ex.message.indexOf("timed out") > -1)
		{
			console.log("Passkey login timed out");
			return { error: "Passkey login timed out.", abort: false };
		}
		console.error(ex);
		let str = "Passkey Login Failed: " + ex.name + ": " + ex.message;
		return { error: str, abort: false, isConditional };
	}
}

function base64ToArrayBuffer(base64)
{
	const bin = atob(base64);
	return Uint8Array.from(bin, c => c.charCodeAt(0));
}

function base64URLToArrayBuffer(base64URL)
{
	const base64 = base64URL.replaceAll('-', '+').replaceAll('_', '/');
	return base64ToArrayBuffer(base64);
}

export function arrayBufferToBase64URL(buffer)
{
	let bin = '';
	const bytes = new Uint8Array(buffer);
	const len = bytes.byteLength;

	for (let i = 0; i < len; i++)
	{
		bin += String.fromCharCode(bytes[i]);
	}
	return btoa(bin)
		.replaceAll('+', '-')
		.replaceAll('/', '_')
		.replaceAll('=', '');
}
/**
 * Returns a promise that resolves to true if this web browser supports Passkeys.
 */
export async function passkey_supportedAsync()
{
	try
	{
		if (window.PublicKeyCredential && PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable)
		{
			let result = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
			if (result === true)
				return true;
		}
	}
	catch (ex)
	{
	}
	return false;
}
/**
 * Returns a promise that resolves to true if this web browser supports WebAuthn conditional mediation (passkey autofill).
 */
export async function passkey_autofillSupportedAsync()
{
	try
	{
		if (window.PublicKeyCredential && PublicKeyCredential.isConditionalMediationAvailable)
		{
			let result = await PublicKeyCredential.isConditionalMediationAvailable();
			if (result === true)
				return true;
		}
	}
	catch (ex)
	{
	}
	return false;
}
/**
 * A substitute for the missing framework-provided deserializer.
 * @param {String} json JSON-encoded string containing a CreationOptions for webauthn (passkeys).
 * @returns CreationOptions object.
 */
function passkey_parseCreationOptionsFromJSON(json)
{
	// Parse the JSON string into an object
	let obj = JSON.parse(json);

	// Convert the object into a PublicKeyCredentialCreationOptions object
	let publicKeyCredentialCreationOptions = {
		challenge: base64URLToArrayBuffer(obj.challenge),
		rp: obj.rp,
		user: {
			id: base64URLToArrayBuffer(obj.user.id),
			name: obj.user.name,
			displayName: obj.user.displayName,
			icon: obj.user.icon
		},
		pubKeyCredParams: obj.pubKeyCredParams,
		timeout: obj.timeout,
		excludeCredentials: obj.excludeCredentials.map(cred => ({
			id: base64URLToArrayBuffer(cred.id),
			type: cred.type,
			transports: cred.transports
		})),
		authenticatorSelection: obj.authenticatorSelection,
		attestation: obj.attestation,
		extensions: obj.extensions
	};

	return publicKeyCredentialCreationOptions;
}
/**
 * A substitute for the missing framework-provided deserializer.
 * @param {String} json JSON-encoded string containing a CreationOptions for webauthn (passkeys).
 * @returns CreationOptions object.
 */
export function passkey_parseRequestOptionsFromJSON(json)
{
	// Parse the JSON string into an object
	let obj = JSON.parse(json);

	// Convert the base64Url strings into ArrayBuffers
	let options = {
		allowCredentials: obj.allowCredentials.map(cred => ({
			id: base64URLToArrayBuffer(cred.id),
			type: cred.type
		})),
		challenge: base64URLToArrayBuffer(obj.challenge),
		rpId: obj.rpId,
		timeout: obj.timeout,
		userVerification: obj.userVerification
	};

	return options;
}
/**
 * Converts the given PublicKeyCredential to a format that can be sent to the server as JSON as part of passkey creation.
 * @param {Object} credential The PublicKeyCredential to convert.
 * @returns A reformatted PublicKeyCredential where ArrayBuffers are now Base64URL strings.
 */
export function passkey_ObjectConvertToServerFormat(credential)
{
	if (!credential.response)
		throw new Error("Credential did not contain a response property.");

	if (credential.response.attestationObject)
	{
		// Assume this is an attestation object involved in passkey registration
		return {
			id: ConvertArrayBuffer(credential.id),
			rawId: ConvertArrayBuffer(credential.rawId),
			type: credential.type,
			response: {
				attestationObject: ConvertArrayBuffer(credential.response.attestationObject),
				clientDataJSON: ConvertArrayBuffer(credential.response.clientDataJSON),
			},
			extensions: credential.extensions
		};
	}
	else
	{
		// Assume this is an assertion object involved in passkey login
		return {
			id: ConvertArrayBuffer(credential.id),
			rawId: ConvertArrayBuffer(credential.rawId),
			authenticatorAttachment: credential.authenticatorAttachment,
			type: credential.type,
			response: {
				authenticatorData: ConvertArrayBuffer(credential.response.authenticatorData),
				clientDataJSON: ConvertArrayBuffer(credential.response.clientDataJSON),
				signature: ConvertArrayBuffer(credential.response.signature),
				userHandle: ConvertArrayBuffer(credential.response.userHandle),
			},
		};
	}
}
function ConvertArrayBuffer(object)
{
	if (typeof object === "undefined")
		return undefined;
	if (typeof object === "object")
		return arrayBufferToBase64URL(object);
	else if (typeof object === "string")
		return object;
	else
		throw new Error('ConvertArrayBuffer does not support argument of type "' + typeof object + '"');
}