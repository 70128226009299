var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "searchResultMetadata",
      attrs: { role: "dialog", "aria-labelledby": "searchMetadataPanelTitle" },
    },
    [
      _c("div", { staticClass: "normalBody" }, [
        _c("div", { staticClass: "heading" }, [
          _c(
            "div",
            {
              ref: "closeBtn",
              staticClass: "closeButton",
              attrs: { tabindex: "0", role: "button", "aria-label": "Close" },
              on: {
                click: _vm.readyToClose,
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ])
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.readyToClose.apply(null, arguments)
                },
              },
            },
            [
              _c("vsvg", {
                attrs: {
                  sprite: "#x",
                  title: "",
                  desc: "",
                  role: "presentation",
                  "aria-hidden": "true",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "title",
              attrs: { id: "searchMetadataPanelTitle", role: "alert" },
            },
            [
              _vm._v(
                "Metadata for searchid " +
                  _vm._s(_vm.searchid) +
                  ": " +
                  _vm._s(_vm.queryLabel)
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "subtitle" }, [
            _vm._v(
              "(This panel and its data requires elevated user permission)"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "metadataBody" },
          _vm._l(_vm.allRequestedCategories, function (cat) {
            return _c("CategoryMetadata", {
              key: cat.CategoryType,
              staticClass: "category",
              attrs: {
                searchid: _vm.searchid,
                categoryType: cat.CategoryType,
                priority: cat.Priority,
              },
            })
          }),
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "statRefResultCountsBody" },
          [
            _c("StatRefResultCounts", {
              staticClass: "category",
              attrs: { searchid: _vm.searchid },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }