var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    _vm._g(
      {
        staticClass: "vsvg",
        attrs: {
          tabindex: _vm.myTabIndex,
          focusable: _vm.focusableValue,
          "aria-label": _vm.desc,
          role: _vm.role,
        },
      },
      _vm.$listeners
    ),
    [
      _c("use", { attrs: { "xlink:href": _vm.sprite, role: _vm.role } }),
      _vm._v(" "),
      _vm.title
        ? _c("title", { attrs: { role: _vm.role } }, [
            _vm._v(_vm._s(_vm.title)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.desc
        ? _c("desc", { attrs: { role: _vm.role } }, [_vm._v(_vm._s(_vm.desc))])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }