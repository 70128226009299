<template>
	<svg class="vsvg" :tabindex="myTabIndex" :focusable="focusableValue" :aria-label="desc" v-on="$listeners" :role="role">
		<!-- force --> <!-- whitespace -->
		<use v-bind:xlink:href="sprite" :role="role"></use> <!-- for --> <!-- old safari -->
		<title :role="role" v-if="title">{{ title }}</title>
		<desc :role="role" v-if="desc">{{ desc }}</desc>
	</svg>
</template>

<script>

	export default {
		props: {
			sprite: {
				type: String,
				required: true
			},
			title: {
				type: String,
				required: true
			},
			desc: {
				type: String,
				required: true
			},
			tabindex: {
				type: String,
				default: null
			},
			isPresentation: {
				type: Boolean,
				default: true
			}
		},
		computed: {
			hasTabIndex()
			{
				return this.tabindex !== null && !isNaN(parseInt(this.tabindex));
			},
			myTabIndex()
			{
				return this.hasTabIndex ? this.tabindex : false; // The "tabindex" attribute will be omitted if this returns false.
			},
			focusableValue()
			{
				return this.hasTabIndex ? "true" : "false"; // The "focusable" attribute will be omitted if this returns false. But we want it to literally have the value "false" so we return strings here instead of boolean values.
			},
			role()
			{
				return this.isPresentation ? "presentation" : null;
			}
		}
	}
</script>
<style scoped>
	.vsvg
	{
		user-select: none;
		overflow: hidden; /* hack for IE 11 otherwise there is a ton of whitespace below the footer on short pages */
	}
</style>