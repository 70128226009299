var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "progressDialogRoot",
      attrs: { role: "dialog", "aria-labelledby": "loadingTitle" }
    },
    [
      _c(
        "div",
        { staticClass: "dialogBody" },
        [
          _c("ScaleLoader"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "title",
              attrs: { id: "loadingTitle", role: "alert" }
            },
            [_vm._v(_vm._s(_vm.text))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }