import { CallServer } from "tdsAppRoot/API/apibase.js";

var MFAUserBeginSetupParams = function ()
{
	var username;
	var password;
	var mfaMethodType;
};
Object.seal(MFAUserBeginSetupParams);

var MFAUserEndSetupParams = function ()
{
	var username;
	var password;
	var mfaMethodType;
	var mfaValue;
	var secretKey;
	var passkeyName; // Ignored if not passkey
};
Object.seal(MFAUserEndSetupParams);

var MFAGetUserDataParams = function ()
{
	var username;
	var password;
	var deviceGuid;
};
Object.seal(MFAGetUserDataParams);

var MFARemoveRememberedDeviceParams = function ()
{
	var username;
	var password;
	var deviceId;
};
Object.seal(MFARemoveRememberedDeviceParams);

var MFADeletePasskeyParams = function ()
{
	var username;
	var password;
	var credentialId;
};
Object.seal(MFADeletePasskeyParams);

var MFASetPasskeyDescriptionParams = function ()
{
	var username;
	var password;
	var credentialId;
	var description;
};
Object.seal(MFASetPasskeyDescriptionParams);

var MFAPasskeyRegisterChallengeParams = function ()
{
	var username;
	var password;
	var credentialId;
	var description;
};
Object.seal(MFASetPasskeyDescriptionParams);
var PasskeyLoginChallengeParams = function ()
{
	var username;
	var password;
};
Object.seal(PasskeyLoginChallengeParams);

/**
 * Step 1/2 of configuring an MFA method for a user account.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {Number} mfaMethodId MFA Method Type
 * @returns {Promise} API Response promise
 */
export function MFAUserBeginSetup(store, user, pass, mfaMethodId)
{
	try
	{
		let args = new MFAUserBeginSetupParams();
		args.username = user;
		args.password = pass;
		args.mfaMethodType = mfaMethodId;
		return CallServer("MFAUser", "BeginSetup", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Step 2/2 of configuring an MFA method for a user account.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {Number} mfaMethodId MFA Method Type
 * @param {String} mfaValue One-time password entered by user.
 * @param {String} secretKey SecretKey string given in response from [MFAUserBeginSetup].  This is compared with what the server knows so that if it doesn't match, we can provide a more accurate error message instead of just having MFA validation fail.
 * @param {String} passkeyName Passkey name.  Ignored if not creating a passkey.
 * @returns {Promise} API Response promise
 */
export function MFAUserEndSetup(store, user, pass, mfaMethodId, mfaValue, secretKey, passkeyName)
{
	try
	{
		let args = new MFAUserEndSetupParams();
		args.username = user;
		args.password = pass;
		args.mfaMethodType = mfaMethodId;
		args.mfaValue = mfaValue;
		args.secretKey = secretKey;
		args.passkeyName = passkeyName;
		return CallServer("MFAUser", "EndSetup", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Gets an MFAUserData instance for the current session.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {String} deviceGuid GUID of this device, if one is stored
 * @returns {Promise} API Response promise
 */
export function MFAGetUserData(store, user, pass, deviceGuid)
{
	try
	{
		let args = new MFAGetUserDataParams();
		args.username = user;
		args.password = pass;
		args.deviceGuid = deviceGuid;
		return CallServer("MFAUser", "GetUserData", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Deletes an MFA method from this user account.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {Number} mfaMethodId MFA Method Type
 * @returns {Promise} API Response promise
 */
export function MFARemoveConfiguration(store, user, pass, mfaMethodId)
{
	try
	{
		let args = new MFAUserBeginSetupParams();
		args.username = user;
		args.password = pass;
		args.mfaMethodType = mfaMethodId;
		return CallServer("MFAUser", "RemoveConfiguration", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Sends an email containing an MFA verification code for this user account.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @returns {Promise} API Response promise
 */
export function MFAUserEmailSend(store, user, pass)
{
	try
	{
		let args = new MFAGetUserDataParams();
		args.username = user;
		args.password = pass;
		return CallServer("MFAUser", "EmailSend", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Deletes a remembered device.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {String} deviceId Device ID (hashed GUID)
 * @returns {Promise} API Response promise
 */
export function MFARemoveRememberedDevice(store, user, pass, deviceId)
{
	try
	{
		let args = new MFARemoveRememberedDeviceParams();
		args.username = user;
		args.password = pass;
		args.deviceId = deviceId;
		return CallServer("MFAUser", "RemoveRememberedDevice", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Enables the Email MFA method.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @returns {Promise} API Response promise
 */
export function MFAEmailMethodSetup(store, user, pass)
{
	try
	{
		let args = new MFAUserBeginSetupParams();
		args.username = user;
		args.password = pass;
		return CallServer("MFAUser", "EmailMethodSetup", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Deletes the specified passkey.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {String} credentialId Credential ID of the passkey, Base64Url encoded.
 * @returns {Promise} API Response promise
 */
export function MFADeletePasskey(store, user, pass, credentialId)
{
	try
	{
		let args = new MFADeletePasskeyParams();
		args.username = user;
		args.password = pass;
		args.credentialId = credentialId;
		return CallServer("MFAUser", "DeletePasskey", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Deletes the specified passkey.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {String} credentialId Credential ID of the passkey, Base64Url encoded.
 * @param {String} description Description to assign to the passkey.
 * @returns {Promise} API Response promise
 */
export function MFASetPasskeyDescription(store, user, pass, credentialId, description)
{
	try
	{
		let args = new MFASetPasskeyDescriptionParams();
		args.username = user;
		args.password = pass;
		args.credentialId = credentialId;
		args.description = description;
		return CallServer("MFAUser", "SetPasskeyDescription", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Gets a Passkey Login Challenge from the server so that the client can complete passkey assertion (passkey login).
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @returns {Promise} API Response promise
 */
export function PasskeyLoginChallenge(store, user, pass)
{
	try
	{
		let args = new PasskeyLoginChallengeParams();
		args.username = user;
		args.password = pass;
		return CallServer("MFAUser", "PasskeyLoginChallenge", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}