<template>
	<div class="alaPopup" role="dialog" aria-label="This account can not be used for login.  Press escape to cancel.">
		<div class="titleBar">
			<div class="title" id="alaMsgTitle" role="alert">
				Personalized Profile Account<br />{{user}}
				<!--<template v-if="isExpired">
					Anywhere Login Account Expired
				</template>
				<template v-else>
					Personalized Profile Account
				</template>-->
			</div>
		</div>
		<div class="dialogBody">
			<div>You have tried to log in using a Personalized Profile Account.</div>
			<template v-if="isExpired">
				<template v-if="expDate">
					<p>Your profile's <b>Anywhere Login Account</b> access to <b>{{groupName}}</b> expired on <b style="white-space:nowrap">{{expDate}}</b>.</p>
				</template>
				<template v-else>
					<p>Your profile's <b>Anywhere Login Account</b> has expired.</p>
				</template>
				<p><a :href="tempAcctHelpLink" role="button" tabindex="0" ref="renewLink" target="_blank" class="alwaysUnvisited">Here are instructions to renew your Anywhere Login Account. <vsvg class="newtab" sprite="#newtab" title="" desc="" role="presentation" aria-hidden="true" /></a></p>
			</template>
			<template v-else>
				<p>Your profile is not an Anywhere Login Account, so it can't be used to log in to a <span v-html="appNameHtml"></span> subscription yet.</p>
				<p><a :href="tempAcctHelpLink" role="button" tabindex="0" ref="renewLink" target="_blank" class="alwaysUnvisited">Read how to enable your "Profile" to be used as an Anywhere Login Account <vsvg class="newtab" sprite="#newtab" title="" desc="" role="presentation" aria-hidden="true" /></a>.</p>
			</template>
			<div>If you no longer need your Personalized Profile Account, you have the option to delete it.</div>
		</div>
		<div class="controls">
			<div role="button" class="dialogButton deleteButton" tabindex="0" @click="deleteMe" @keydown.space.enter.prevent="deleteMe">
				Delete My Profile
			</div>
			<div ref="btnCancel" role="button" class="dialogButton cancelButton" tabindex="0" @click="closeMe" @keydown.space.enter.prevent="closeMe">
				Cancel
			</div>
		</div>
		<FullScreenLoadingMsg :show="isDeletingProfile" msg="Deleting Profile…" />
	</div>
</template>

<script>
	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";
	import { ShowModalErrorWindow, DefaultErrorHandler } from "tdsAppRoot/library/ErrorReporter.js";
	import { DeleteProfileWithCredentials } from 'tdsAppRoot/API/MyStatrefAPI';
	import { TextInputDialog, ModalMessageDialog } from "tdsAppRoot/library/ModalDialog.js";
	import svg1 from "tdsAppRoot/images/sprite/newtab.svg";

	export default {
		components: { FullScreenLoadingMsg },
		props:
		{
			user: "",
			pass: "",
			isExpired: true,
			groupName: "",
			expDate: ""
		},
		data()
		{
			return {
				tempAcctHelpLink: appContext.appPath + 'PrefsLoginGuide',
				isDeletingProfile: false,
				openedAt: 0,
				appNameHtml: appContext.appNameHtml
			};
		},
		created()
		{
			this.openedAt = performance.now();
		},
		methods:
		{
			DefaultClose()
			{
				if (performance.now() > this.openedAt + 1000)
					this.$emit('close', false);
			},
			SetFocus()
			{
				if (this.$refs.renewLink)
					this.$refs.renewLink.focus();
			},
			closeMe()
			{
				this.$emit('close', false);
			},
			deleteMe()
			{
				TextInputDialog({ message: "This will delete your entire Personalized Profile Account.  Are you sure?  Type DELETE to confirm." }).then(answer =>
				{
					if (answer && answer.value)
					{
						if (answer.value === "DELETE")
						{
							this.isDeletingProfile = true;
							DeleteProfileWithCredentials(this.$store, this.user, this.pass)
								.then(data =>
								{
									ModalMessageDialog("The profile " + this.user + " has been deleted.", "Profile Deleted")
										.then(() =>
										{
											this.closeMe();
										});
								})
								.catch(DefaultErrorHandler)
								.finally(() =>
								{
									this.isDeletingProfile = false;
								});
						}
						else
						{
							ShowModalErrorWindow("Confirmation text was entered incorrectly.");
						}
					}
				});
		}
	}
	}
</script>
<style scoped>
	.alaPopup
	{
		background-color: #FFFFFF;
		max-width: 425px;
		max-height: 92vh;
		overflow-y: auto;
		box-sizing: border-box;
	}

	.titleBar
	{
		padding: 8px 14px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.dialogBody
	{
		padding: 8px 14px 20px 14px;
		box-sizing: border-box;
	}

	.newtab
	{
		fill: currentColor;
		width: 12px;
		height: 12px;
	}

	.controls
	{
		display: flex;
	}

	.dialogButton
	{
		display: inline-block;
		cursor: pointer;
		color: black;
		font-weight: bold;
		font-size: 12pt;
		box-sizing: border-box;
		position: relative;
		padding: 12px 5px;
		flex: 1 0 auto;
		text-align: center;
	}

		.dialogButton:focus
		{
			outline: none;
		}

		.dialogButton:focus-visible
		{
			border: 2px solid black;
			padding: 10px 3px;
		}

		.dialogButton.focus-visible
		{
			border: 2px solid black;
			padding: 10px 3px;
		}

		.dialogButton:hover
		{
			background-color: rgba(0,0,0,0.05);
		}

	.deleteButton
	{
		color: #A62121;
		border-bottom-left-radius: 10px;
	}

	.cancelButton
	{
		color: #A66621;
		border-left: 1px solid #DDDDDD;
		border-bottom-right-radius: 10px;
	}
</style>