var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "alaPopup",
      attrs: {
        role: "dialog",
        "aria-label":
          "This account can not be used for login.  Press escape to cancel.",
      },
    },
    [
      _c("div", { staticClass: "titleBar" }, [
        _c(
          "div",
          { staticClass: "title", attrs: { id: "alaMsgTitle", role: "alert" } },
          [
            _vm._v("\n\t\t\tPersonalized Profile Account"),
            _c("br"),
            _vm._v(_vm._s(_vm.user) + "\n\t\t\t"),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogBody" },
        [
          _c("div", [
            _vm._v(
              "You have tried to log in using a Personalized Profile Account."
            ),
          ]),
          _vm._v(" "),
          _vm.isExpired
            ? [
                _vm.expDate
                  ? [
                      _c("p", [
                        _vm._v("Your profile's "),
                        _c("b", [_vm._v("Anywhere Login Account")]),
                        _vm._v(" access to "),
                        _c("b", [_vm._v(_vm._s(_vm.groupName))]),
                        _vm._v(" expired on "),
                        _c("b", { staticStyle: { "white-space": "nowrap" } }, [
                          _vm._v(_vm._s(_vm.expDate)),
                        ]),
                        _vm._v("."),
                      ]),
                    ]
                  : [_vm._m(0)],
                _vm._v(" "),
                _c("p", [
                  _c(
                    "a",
                    {
                      ref: "renewLink",
                      staticClass: "alwaysUnvisited",
                      attrs: {
                        href: _vm.tempAcctHelpLink,
                        role: "button",
                        tabindex: "0",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "Here are instructions to renew your Anywhere Login Account. "
                      ),
                      _c("vsvg", {
                        staticClass: "newtab",
                        attrs: {
                          sprite: "#newtab",
                          title: "",
                          desc: "",
                          role: "presentation",
                          "aria-hidden": "true",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : [
                _c("p", [
                  _vm._v(
                    "Your profile is not an Anywhere Login Account, so it can't be used to log in to a "
                  ),
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.appNameHtml) },
                  }),
                  _vm._v(" subscription yet."),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c(
                    "a",
                    {
                      ref: "renewLink",
                      staticClass: "alwaysUnvisited",
                      attrs: {
                        href: _vm.tempAcctHelpLink,
                        role: "button",
                        tabindex: "0",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        'Read how to enable your "Profile" to be used as an Anywhere Login Account '
                      ),
                      _c("vsvg", {
                        staticClass: "newtab",
                        attrs: {
                          sprite: "#newtab",
                          title: "",
                          desc: "",
                          role: "presentation",
                          "aria-hidden": "true",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v("."),
                ]),
              ],
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "If you no longer need your Personalized Profile Account, you have the option to delete it."
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "controls" }, [
        _c(
          "div",
          {
            staticClass: "dialogButton deleteButton",
            attrs: { role: "button", tabindex: "0" },
            on: {
              click: _vm.deleteMe,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ]) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.deleteMe.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\tDelete My Profile\n\t\t")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "btnCancel",
            staticClass: "dialogButton cancelButton",
            attrs: { role: "button", tabindex: "0" },
            on: {
              click: _vm.closeMe,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ]) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.closeMe.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\tCancel\n\t\t")]
        ),
      ]),
      _vm._v(" "),
      _c("FullScreenLoadingMsg", {
        attrs: { show: _vm.isDeletingProfile, msg: "Deleting Profile…" },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Your profile's "),
      _c("b", [_vm._v("Anywhere Login Account")]),
      _vm._v(" has expired."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }