<template>
	<div class="passkeyRecord" tabindex="0">
		<div class="passkeyTitlebar">
			<vsvg class="passkeyIcon" sprite="#FIDO_Passkey_mark_A_black" title="" desc="" />
			<div class="passkeyName">{{passkey.Description ? passkey.Description : "Unnamed passkey"}}</div>
			<template v-if="editable">
				<SvgButton class="passkeyButton" sprite="#rename" title="Rename passkey" desc="" @click="RenamePasskey" :disabled="disableMyButtons" />
				<SvgButton class="passkeyButton" sprite="#delete_forever" title="Delete passkey" desc="" @click="$emit('delete', passkey)" :disabled="disableMyButtons" />
			</template>
		</div>
		<div class="passkeyField passkeyId" :title="'Passkey credential ID: ' + passkey.Id"><b>Credential ID:</b> {{passkey.Id}}</div>
		<div class="passkeyField"><b>Created:</b> {{DateStr(passkey.RegDate)}}</div>
		<div class="passkeyField"><b>Last used:</b> {{DateStr(passkey.LastUsed)}}</div>
		<div class="passkeyBusy" v-if="busy">
			<div class="passkeyBusyContent">
				<ScaleLoader class="animation" />
			</div>
		</div>
	</div>
</template>
<script>
	import svg1 from "tdsAppRoot/images/sprite/delete_forever.svg";
	import svg2 from "tdsAppRoot/images/sprite/FIDO_Passkey_mark_A_black.svg";
	import svg3 from "tdsAppRoot/images/sprite/rename.svg";
	import { DefaultErrorHandler } from "tdsAppRoot/library/ErrorReporter";
	import { MFASetPasskeyDescription } from "tdsAppRoot/API/MFAAPI.js";
	import { TextInputDialog } from "tdsAppRoot/library/ModalDialog.js";
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';

	export default {
		components: { ScaleLoader },
		props:
		{
			passkey: {
				type: Object,
				required: true
			},
			editable: {
				type: Boolean,
				default: false
			},
			disableButtons: {
				type: Boolean,
				default: false
			},
			UN: {
				type: String,
				default: ""
			},
			PW: {
				type: String,
				default: ""
			},
		},
		data()
		{
			return {
				renamingPasskey: false
			};
		},
		computed:
		{
			busy()
			{
				return this.renamingPasskey;
			},
			disableMyButtons()
			{
				return this.renamingPasskey || this.disableButtons;
			}
		},
		methods:
		{
			DateStr(ts)
			{
				if (ts <= 0)
					return "never";
				return new Date(ts).toLocaleString();
			},
			async RenamePasskey()
			{
				if (this.disableMyButtons)
					return;
				try
				{
					let result = await TextInputDialog({
						title: "Rename Passkey"
						, message: "Passkey name:"
						, placeholder: "name"
						, initialText: this.passkey.Description
						, maxTextLength: 512
						, allowEmptyInput: true
					});
					if (result)
					{
						let newDescription = result.value.trim();
						if (this.passkey.Description !== newDescription)
						{
							this.renamingPasskey = true;
							await MFASetPasskeyDescription(this.$store, this.UN, this.PW, this.passkey.Id, newDescription);
							this.passkey.Description = newDescription;
						}
					}
				}
				catch (ex)
				{
					DefaultErrorHandler(ex);
				}
				finally
				{
					this.renamingPasskey = false;
				}
			},
		}
	}
</script>
<style scoped>
	.passkeyButton
	{
		flex: 0 0 auto;
	}

	.passkeyRecord
	{
		position: relative;
		border: 1px solid #AAAAAA;
		border-radius: 10px;
		padding: 0.25em 0.5em 0.5em 0.8em;
		margin: 1em 0px;
	}

	.passkeyBusy
	{
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		background-color: rgba(0,0,0,0.2);
		display: flex;
		justify-content: center;
		align-items: center;
		animation: 1s linear fadein;
	}

	.passkeyBusyContent
	{
		background-color: #FFFFFF;
		border: 1px solid black;
		border-radius: 5px;
		padding: 0.5em 1em;
		box-shadow: 2px 2px 8px black;
	}

	@keyframes fadein
	{
		0%
		{
			opacity: 0;
		}

		33%
		{
			opacity: 0;
		}

		100%
		{
			opacity: 1;
		}
	}

	.passkeyField
	{
		margin-top: 0.33em;
	}

	.passkeyTitlebar
	{
		display: flex;
		align-items: center;
	}

	.passkeyName
	{
		flex: 1 1 auto;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 1.2em;
	}

	.passkeyId
	{
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.passkeyIcon
	{
		flex: 0 0 auto;
		width: 40px;
		height: 40px;
	}

	.rightAlignContent
	{
		display: flex;
		justify-content: flex-end;
	}
</style>
