var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mfaInterface" },
    [
      _vm.mode === "initial_setup" || _vm.mode === "setup"
        ? _c(
            "div",
            { staticClass: "titleBar" },
            [
              _vm._v("Multi-Factor Authentication Setup "),
              _c("LogoImage", {
                staticClass: "logoImg",
                attrs: {
                  includeDesc: true,
                  width: 60,
                  height: 29,
                  logoType: "standalone",
                  rolePresentation: true
                }
              })
            ],
            1
          )
        : _vm.mode === "challenge"
        ? _c("div", { staticClass: "titleBar" }, [
            _vm._v("Authentication challenge")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "body" },
        [
          _vm.error
            ? _c("div", { staticClass: "errorMessage" }, [
                _c("p", [_vm._v(_vm._s(_vm.error))]),
                _vm._v(" "),
                _vm.httpsLink
                  ? _c("p", [
                      _c("a", { attrs: { href: _vm.httpsLink } }, [
                        _vm._v(_vm._s(_vm.httpsLink))
                      ])
                    ])
                  : _vm._e()
              ])
            : [
                !_vm.password && _vm.passwordEntryRequired
                  ? [
                      _c("label", [
                        _vm._v(
                          '\n\t\t\t\t\tTo continue, please confirm the password for user "' +
                            _vm._s(_vm.UN) +
                            '":\n\t\t\t\t\t'
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "centerContent",
                            staticStyle: { "margin-top": "1em" }
                          },
                          [
                            _c("input", {
                              staticClass: "tb",
                              staticStyle: { display: "none" },
                              attrs: { type: "text", autocomplete: "username" }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.PW,
                                  expression: "PW"
                                }
                              ],
                              staticClass: "tb",
                              attrs: {
                                type: "password",
                                autocomplete: "current-password"
                              },
                              domProps: { value: _vm.PW },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.PW = $event.target.value
                                }
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "margin-top": "1em" } }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "medButton primaryColorButton fullWidthButton",
                            attrs: { tabindex: "0" },
                            on: {
                              click: function($event) {
                                return _vm.ConfirmPassword()
                              }
                            }
                          },
                          [_vm._v("Confirm Password")]
                        )
                      ])
                    ]
                  : [
                      _c(
                        "TransitionTwoPanel",
                        {
                          attrs: { isShowingLeftPanel: _vm.isShowingLeftPanel },
                          on: {
                            start: _vm.TransitionStart,
                            end: _vm.TransitionEnd
                          }
                        },
                        [
                          !_vm.selectedMfaMethod
                            ? _c(
                                "div",
                                {
                                  key: "methodList",
                                  staticClass: "methodList"
                                },
                                [
                                  _vm.mode === "initial_setup"
                                    ? _c(
                                        "div",
                                        { staticClass: "descriptionBlock" },
                                        [
                                          _vm._v(
                                            'The user "' +
                                              _vm._s(_vm.UN) +
                                              '" is required to configure an MFA method to proceed with login.'
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.mode === "challenge"
                                    ? _c(
                                        "div",
                                        { staticClass: "descriptionBlock" },
                                        [_vm._v("Select MFA method:")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.mode === "setup"
                                    ? _c(
                                        "div",
                                        { staticClass: "descriptionBlock" },
                                        [_vm._v("Configure MFA methods:")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.mfaMethodsAvailable.length
                                    ? _c(
                                        "div",
                                        { staticClass: "descriptionBlock" },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\tNo MFA methods are available to your user account.\n\t\t\t\t\t\t\t"
                                          ),
                                          _vm.mode === "initial_setup"
                                            ? _c("span", [
                                                _vm._v("Please "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "alwaysUnvisited",
                                                    attrs: {
                                                      role: "button",
                                                      "aria-haspopup": "dialog",
                                                      tabindex: "0"
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.displayContactUs,
                                                      keydown: function(
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "space",
                                                            32,
                                                            $event.key,
                                                            [" ", "Spacebar"]
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.displayContactUs.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("contact support")]
                                                ),
                                                _vm._v(" for help.")
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.mfaMethodsAvailable, function(
                                    method
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: method.ID,
                                        staticClass: "mfaMethodListItem",
                                        attrs: {
                                          role: "button",
                                          tabindex: "0",
                                          "data-methodid": method.ID
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.SelectMfaMethod(method)
                                          },
                                          keydown: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "space",
                                                32,
                                                $event.key,
                                                [" ", "Spacebar"]
                                              ) &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                            return _vm.SelectMfaMethod(method)
                                          }
                                        }
                                      },
                                      [
                                        _vm.mode === "setup"
                                          ? _c("vsvg", {
                                              class: {
                                                methodIcon: true,
                                                mfaMethodCheckmark: true,
                                                greenCheckmark:
                                                  method.Configured
                                              },
                                              attrs: {
                                                sprite: "#checkmark",
                                                title: "",
                                                desc: "",
                                                "aria-hidden": "true"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("vsvg", {
                                          staticClass: "methodIcon",
                                          attrs: {
                                            sprite:
                                              "#" +
                                              _vm.GetMethodSpriteId(method),
                                            title: "",
                                            desc: "",
                                            "aria-hidden": "true"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "methodName" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.GetMethodName(method))
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("vsvg", {
                                          staticClass: "rightArrow",
                                          attrs: {
                                            sprite: "#arrow_down_thin2",
                                            title: "",
                                            desc: "",
                                            "aria-hidden": "true"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _c(
                                "div",
                                {
                                  key: "selectedMethod",
                                  staticClass: "selectedMethod"
                                },
                                [
                                  !_vm.noMfaMethodList
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "backToMfaList",
                                          attrs: {
                                            role: "button",
                                            tabindex: "0"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.SelectMfaMethod(null)
                                            },
                                            keydown: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "space",
                                                  32,
                                                  $event.key,
                                                  [" ", "Spacebar"]
                                                ) &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              $event.preventDefault()
                                              return _vm.SelectMfaMethod(null)
                                            }
                                          }
                                        },
                                        [
                                          _c("vsvg", {
                                            staticClass: "leftArrow",
                                            attrs: {
                                              sprite: "#arrow_down_thin2",
                                              title: "",
                                              desc: "",
                                              "aria-hidden": "true"
                                            }
                                          }),
                                          _vm._v(
                                            " Back to method list\n\t\t\t\t\t\t"
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "descriptionBlock mfaNameHeading"
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.selectedMfaMethod.Name)
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.selectedMfaMethod.ID === 3
                                    ? [
                                        _c("MFAPasskeys", {
                                          attrs: {
                                            passkeys: _vm.passkeys,
                                            UN: _vm.UN,
                                            PW: _vm.PW,
                                            isLoginChallenge:
                                              _vm.mode === "challenge",
                                            passkeySupported:
                                              _vm.passkeySupported,
                                            canRememberDevice:
                                              _vm.canRememberDevice
                                          },
                                          on: {
                                            delete: _vm.PasskeyDeleted,
                                            create: _vm.PasskeyCreated,
                                            assert: _vm.PasskeyAsserted,
                                            cancel:
                                              _vm.PasskeyAssertionCancelled
                                          }
                                        })
                                      ]
                                    : _vm.selectedMfaMethod.Configured ||
                                      _vm.pendingMethodData
                                    ? [
                                        _vm.selectedMfaMethod.Configured &&
                                        _vm.mode !== "challenge"
                                          ? [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "medButton redHighButton fullWidthButton",
                                                  attrs: { tabindex: "0" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.RemoveConfiguration()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Remove this configuration"
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm.selectedMfaMethod.ID === 1
                                          ? [
                                              _vm.pendingMethodData
                                                ? _c("MFAAuthenticatorExport", {
                                                    attrs: {
                                                      data:
                                                        _vm.pendingMethodData,
                                                      username: _vm.UN
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("MFACodeEntry", {
                                                ref: "codeEntry",
                                                attrs: {
                                                  method: _vm.selectedMfaMethod,
                                                  canRememberDevice:
                                                    _vm.canRememberDevice
                                                },
                                                on: {
                                                  submit: _vm.SubmitOTPCode
                                                }
                                              })
                                            ]
                                          : _vm.selectedMfaMethod.ID === 2
                                          ? [
                                              _c("MFACodeEntry", {
                                                ref: "codeEntry",
                                                attrs: {
                                                  method: _vm.selectedMfaMethod,
                                                  canRememberDevice:
                                                    _vm.canRememberDevice
                                                },
                                                on: {
                                                  submit: _vm.SubmitOTPCode,
                                                  resendemail: function(
                                                    $event
                                                  ) {
                                                    return _vm.SendEmailCode(
                                                      true
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          : _c("div", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\tAn error has occurred. The selected Multi-Factor Authentication method is not recognized.\n\t\t\t\t\t\t\t"
                                              )
                                            ])
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          [
                                            _vm.selectedMfaMethod.ID === 1
                                              ? [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "medButton primaryColorButton fullWidthButton",
                                                      attrs: { tabindex: "0" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.BeginSetup()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Begin Setup")]
                                                  )
                                                ]
                                              : _vm.selectedMfaMethod.ID === 2
                                              ? [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "medButton primaryColorButton fullWidthButton",
                                                      attrs: { tabindex: "0" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.EnableEmailMethod()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Enable Emailed-Code Method"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\tAn error has occurred. The selected Multi-Factor Authentication method is not recognized.\n\t\t\t\t\t\t\t\t"
                                                  )
                                                ]
                                          ],
                                          2
                                        )
                                      ]
                                ],
                                2
                              )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isShowingLeftPanel
                        ? [
                            _vm.mfaRequired && _vm.mode !== "challenge"
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-top": "1em" } },
                                  [
                                    _vm._v(
                                      '\n\t\t\t\t\t\t* Your user account requires MFA for login.  If no MFA method is enabled, the "Email" method is automatically enabled for you.\n\t\t\t\t\t'
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.mode === "setup"
                        ? _c(
                            "div",
                            { staticStyle: { "margin-top": "4em" } },
                            [
                              _c("MFARememberedDevices", {
                                attrs: {
                                  rememberedDevices: _vm.rememberedDevices,
                                  allowedToRemember: _vm.allowedToRemember,
                                  ableToRemember: _vm.ableToRemember,
                                  isDeleting: _vm.deletingRememberedDevice
                                },
                                on: { delete: _vm.RemoveRememberedDevice }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.passkeysAllowed && _vm.mode !== "challenge"
                        ? _c("div", { staticStyle: { "margin-top": "1em" } }, [
                            _vm._v(
                              "\n\t\t\t\t\t* The passkey icon is a trademark of FIDO Alliance, Inc.\n\t\t\t\t"
                            )
                          ])
                        : _vm._e()
                    ],
                _vm._v(" "),
                !_vm.noCancelButton
                  ? _c(
                      "div",
                      {
                        staticClass: "rightAlignContent",
                        staticStyle: { "margin-top": "2em" }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "medButton redHighButton",
                            attrs: { tabindex: "0" },
                            on: {
                              click: function($event) {
                                return _vm.Cancel()
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      ]
                    )
                  : _vm._e()
              ]
        ],
        2
      ),
      _vm._v(" "),
      _c("FullScreenLoadingMsg", {
        attrs: { show: _vm.showLoadingOverlay, msg: "Loading" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }