<template>
	<div class="mfaPasskeySection">
		<div class="body" v-if="isLoginChallenge">
			<div class="passkeySectionDescription" v-if="!passkeySupported">This web browser does not support passkeys.</div>
			<div class="passkeySectionDescription" v-else-if="isAssertionPending">
				<p>Your device should be asking you to authenticate with a passkey.</p>
				<div><ScaleLoader class="animation" /></div>
			</div>
			<div class="passkeySectionDescription" v-else-if="assertionError">{{assertionError}}</div>
			<div class="passkeySectionDescription" v-else-if="passkeyAssertionCompleted">
				<p>Logging in with passkey…</p>
				<MFAPasskeyRecord v-if="assertingPasskey" :passkey="assertingPasskey" />
				<div><ScaleLoader class="animation" /></div>
			</div>
			<div class="passkeySectionDescription" v-else-if="assertionObject">
				<div class="passkeySectionDescription"></div>
				<p>Ready to submit login with passkey:</p>
				<MFAPasskeyRecord v-if="assertingPasskey" :passkey="assertingPasskey" />
				<div class="rightAlignContent" style="margin: 1em 0px;" v-if="canRememberDevice">
					<SRCustomCheckbox v-model="rememberDevice" :square="true" :size="16">
						Remember this device
					</SRCustomCheckbox>
				</div>
				<button class="medButton primaryColorButton fullWidthButton" @click="SubmitAssertion()" tabindex="0">Submit</button>
			</div>
		</div>
		<div class="body" v-else>
			<div class="passkeySectionDescription"><span v-html="appNameHtml"></span> allows a <a href="https://fidoalliance.org/passkeys/" target="_blank">passkey</a> to be used as a Multi-Factor Authentication method.</div>
			<div v-if="passkeys.length === 0">
				Your user account does not have any passkeys.
			</div>
			<div v-else :class="{ passkeyList: true, isDeleting: isDeleting }">
				<div class="passkeyListHeader">
					You have {{passkeys.length}} passkey{{passkeys.length === 1 ? '' : 's'}}:
				</div>
				<MFAPasskeyRecord v-for="passkey in passkeys" :key="passkey.Id"
								  :passkey="passkey" :editable="true"
								  :disableButtons="disableButtons" :UN="UN" :PW="PW"
								  @delete="DeletePasskey(passkey)" />
			</div>
			<div class="addPasskeySection" v-if="passkeySupported">
				<button class="medButton primaryColorButton fullWidthButton" @click="CreatePasskey" tabindex="0">Create passkey</button>
			</div>
			<div v-else>
				This web browser does not support passkeys.
			</div>
		</div>
	</div>
</template>
<script>
	import svg1 from "tdsAppRoot/images/sprite/delete_forever.svg";
	import svg3 from "tdsAppRoot/images/sprite/rename.svg";
	import { DefaultErrorHandler } from "tdsAppRoot/library/ErrorReporter";
	import { MFADeletePasskey } from "tdsAppRoot/API/MFAAPI.js";
	import { ModalConfirmDialog } from "tdsAppRoot/library/ModalDialog.js";
	import { BeginCreatePasskey, LoginWithPasskey, cancelPasskeyOperation } from "tdsAppRoot/library/Passkeys";
	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';
	import SRCustomCheckbox from "tdsAppRoot/components/Controls/SRCustomCheckbox.vue";
	import MFAPasskeyRecord from "tdsAppRoot/components/MFA/MFAPasskeyRecord.vue";

	export default {
		components: { FullScreenLoadingMsg, ScaleLoader, SRCustomCheckbox, MFAPasskeyRecord },
		props:
		{
			passkeys: {
				type: Array,
				required: true
			},
			isDeleting: {
				type: Boolean,
				default: false
			},
			UN: {
				type: String,
				default: ""
			},
			PW: {
				type: String,
				default: ""
			},
			isLoginChallenge: {
				type: Boolean,
				required: true
			},
			passkeySupported: {
				type: Boolean,
				default: false
			},
			canRememberDevice: {
				type: Boolean,
				required: true
			},
		},
		data()
		{
			return {
				appNameHtml: appContext.appNameHtml,
				creatingPasskey: false,
				isAssertionPending: false,
				assertionObject: null,
				assertionError: null,
				assertingCredentialId: null,
				rememberDevice: false,
				passkeyAssertionCompleted: false,
			};
		},
		created()
		{
			if (this.isLoginChallenge)
				this.BeginPasskeyAssertion();
		},
		destroyed()
		{
			cancelPasskeyOperation();
		},
		mounted()
		{
		},
		computed:
		{
			disableButtons()
			{
				return this.isDeleting;
			},
			assertingPasskey()
			{
				if (this.assertingCredentialId)
				{
					for (let i = 0; i < this.passkeys.length; i++)
					{
						if (this.passkeys[i].Id === this.assertingCredentialId)
							return this.passkeys[i];
					}
				}
				return null;
			}
		},
		methods:
		{
			async CreatePasskey()
			{
				if (this.creatingPasskey)
					return;
				this.creatingPasskey = true;
				try
				{
					await BeginCreatePasskey(this.$store, this.UN, this.PW);
					this.$emit('create');
				}
				catch (err)
				{
					DefaultErrorHandler(err);
				}
				finally
				{
					this.creatingPasskey = false;
				}
			},
			async DeletePasskey(passkey)
			{
				if (this.disableButtons)
					return;
				if (await ModalConfirmDialog("You are about to delete the passkey \"" + this.GetDescription(passkey) + "\".", "Confirm", "Delete", "Cancel"))
				{
					this.deletingPasskey = true;
					try
					{
						await MFADeletePasskey(this.$store, this.UN, this.PW, passkey.Id);
						this.$emit('delete');
					}
					catch (err)
					{
						DefaultErrorHandler(err);
					}
					finally
					{
						this.deletingPasskey = false;
					}
				}
			},
			async BeginPasskeyAssertion()
			{
				this.isAssertionPending = true;
				try
				{
					let result = await LoginWithPasskey(this.$store, this.UN, this.PW);
					if (result.error)
						this.assertionError = result.error;
					else
					{
						this.assertionObject = result;
						this.assertingCredentialId = result.passkeyCredentialId;
					}
					if (result.abort)
						this.$emit('cancel');
				}
				catch (err)
				{
					let str = "Passkey Login Error: " + ex.name + ": " + ex.message;
					this.assertionError = str;
				}
				finally
				{
					this.isAssertionPending = false;
				}
			},
			SubmitAssertion()
			{
				let assertionResult = {
					mfaPayload: this.assertionObject.mfaPayload,
					rememberDevice: this.rememberDevice,
				};
				this.$emit('assert', assertionResult);

				this.passkeyAssertionCompleted = true;
			},
			GetDescription(passkey)
			{
				return passkey.Description ? passkey.Description : "Unnamed passkey";
			},
		},
		watch:
		{
		}
	}
</script>
<style scoped>
	a
	{
		text-decoration: underline;
	}

	.isDeleting
	{
		filter: blur(2px);
	}

	.body
	{
		margin: 2px;
	}

	.passkeySectionDescription
	{
		margin: 1em 0px;
	}

	.passkeyList
	{
	}

	.passkeyListHeader
	{
		font-weight: bold;
		font-size: 1.2em;
		margin-bottom: 1em;
		color: #333333;
	}

	.addPasskeySection
	{
		margin: 1em 0px;
	}

	.fullWidthButton
	{
		width: 100%;
	}

	.rightAlignContent
	{
		display: flex;
		justify-content: flex-end;
	}
</style>
