var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: {
        id: "mySRLoginDialog",
        role: "dialog",
        "aria-describedby": "proflogindesc",
        "aria-label":
          "Personalized Profile Account login.  Press escape to close.",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { display: "none" }, attrs: { id: "proflogindesc" } },
        [
          _vm._v(
            "\n\t\tPersonalized Profile Account login.  Press escape to close.\n\t"
          ),
        ]
      ),
      _vm._v(" "),
      _c("MySRLogin", {
        ref: "loginCtrl",
        attrs: {
          isInPopup: true,
          disableAutomaticDocReload: _vm.disableAutomaticDocReload,
        },
        on: { success: _vm.onSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }